import axiosInstance from '../helpers/axiosInstance'; // Assuming axios is used for HTTP requests

export const getAutoRenewStatus = async () => {
  try {
    const response = await axiosInstance.get('/stripe/get-auto-renew-status');
    if (response.data.success) {
      return response.data;
    } else {
      throw new Error('Failed to fetch auto-renew status');
    }
  } catch (error) {
    console.error('Error fetching auto-renew status:', error);
    throw error;
  }
};

export const toggleAutoRenew = async (payload) => {
  try {
    const response = await axiosInstance.post('/stripe/toggle-auto-renew', payload);
    if (response.data.success) {
      return response.data;
    } else {
      throw new Error('Failed to toggle auto-renew status');
    }
  } catch (error) {
    console.error('Error toggling auto-renew status:', error);
    throw error;
  }
};

export const updatePaymentMethod = async () => {
  try {
    const response = await axiosInstance.post("/stripe/update-card");
    return response.data;
  } catch (error) {
    throw error.response?.data || error;
  }
};
