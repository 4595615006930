import React, { useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import usePersistedState from "./../usePersistedState";
import {
  Box,
  HStack,
  Text,
  Flex,
  useToast,
  Accordion,
  AccordionItem,
  AccordionButton,
  IconButton,
  Image,
  Collapse,
  VStack,
  Button,
  ButtonGroup,
  Heading,
  Link,
  Divider,
  Spacer,
} from "@chakra-ui/react";
import { DeleteIcon, ChevronDownIcon } from "@chakra-ui/icons";
import "../../styles/test.css";
import { useColorMode } from "@chakra-ui/react";
import axiosInstance from "../../helpers/axiosInstance";
// import logo from "../../assets/images/logo.png";
import logo from "../../assets/images/logo-home-dark.png";
import CreateButton from "./CreateButton";
import CurrentUserOrganization from "../CurrentUserOrganization";

const Program_CRUD = ({
  selectedProgram,
  setSelectedProgram,
  programName,
  setProgramName,
  fetchSelectedProgram,
  programs,
  setPrograms,
  showInputField,
  setShowInputField,
  currentProgram,
  setCurrentProgram,
  handleAddProgram,
  isAdding,
  setIsAdding,
  fetchAllPrograms,
  contentType,
  setContentType,
}) => {
  const toast = useToast();
  const { colorMode } = useColorMode();
  // Persist dropdown open/close state
  const [isDropdownOpen, setIsDropdownOpen] = usePersistedState(
    "isDropdownOpen",
    true
  );
  const company_name = localStorage.getItem("company_name");
  // Reload the page after changing content type
  const handleChangeContentType = (newType) => {
    setContentType(newType);
    window.location.reload();
    setSelectedProgram(null);
  };

  const handleRemoveProgram = async (programToRemove) => {
    if (programToRemove._id) {
      try {
        const response = await axiosInstance.delete(
          `/program/delete-program/${programToRemove._id}`
        );
        if (response.status === 200) {
          toast({
            title: "Success",
            description: response.data.message,
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top-right",
          });

          if (selectedProgram && selectedProgram._id === programToRemove._id) {
            setSelectedProgram(null);
            setProgramName("");
            setCurrentProgram("");
          }

          fetchAllPrograms();
        }
      } catch (error) {
        if (error.response?.status === 501) {
          toast({
            title: "Error",
            description: error.response.data.message,
            status: "error",
            duration: 2000,
            isClosable: true,
            position: "top-right",
          });

          if (selectedProgram && selectedProgram._id === programToRemove._id) {
            setSelectedProgram(null);
            setProgramName("");
            setCurrentProgram("");
          }

          fetchAllPrograms();
        } else {
          console.error(error);
          let errorMessage = "An error occurred while deleting the program.";
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            errorMessage = error.response.data.message;
          } else if (error.message) {
            errorMessage = error.message;
          }

          console.error("Error deleting program:", error);
          toast({
            title: "Error",
            description: errorMessage,
            status: "error",
            duration: 3000,
            isClosable: true,
            position: "top-right",
          });
        }
      }
    }
  };

  useEffect(() => {
    fetchAllPrograms();
  }, []);

  return (
    <Box
      w="100%"
      h="100vh"
      bg="#FAC900"
      borderRightRadius="xl"
      display="flex"
      flexDirection="column"
    >
      {/* Top Section with Logo & Org Name */}
      <Box
        p={4}
        pt={16}
        width={{ base: "100%", md: "100%" }}
        height={{ base: "10vh", md: "10vh" }}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <HStack spacing={1}>
          <Link as={RouterLink} to="/home">
            <Image
              src={logo}
              alt="Logo"
              height="70px"
              mr={2}
              borderRadius="lg"
            />
          </Link>
        </HStack>
      </Box>

      {/* Content Type: Modern Toggle */}
      <Box h="auto" p={4} pb={0} mb={2}>
        <Text fontSize="md" fontWeight="bold" color="black" mb={2}>
          Content Type:
        </Text>
        <ButtonGroup size="sm" isAttached variant="outline">
          <Button
            bg={contentType === "personalised" ? "white" : "gray.700"}
            color={contentType === "personalised" ? "black" : "white"}
            fontWeight={contentType === "personalised" ? "bold" : "normal"}
            _hover={{ bg: "white", color: "black" }}
            borderColor={contentType === "personalised" ? "black" : "gray.500"}
            onClick={() => handleChangeContentType("personalised")}
          >
            Personalised
          </Button>
          <Button
            bg={contentType === "company" ? "white" : "gray.700"}
            color={contentType === "company" ? "black" : "white"}
            fontWeight={contentType === "company" ? "bold" : "normal"}
            _hover={{ bg: "white", color: "black" }}
            borderColor={contentType === "company" ? "black" : "gray.500"}
            onClick={() => handleChangeContentType("company")}
          >
            Company
          </Button>
        </ButtonGroup>
      </Box>

      {/* Main Content Section (Scrollable) */}
      <Box
        mb={2}
        overflowY="scroll"
        flex="1" // Make this section take up remaining space
        sx={{
          "&::-webkit-scrollbar": { display: "none" },
          msOverflowStyle: "none",
          scrollbarWidth: "none",
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          w="100%"
          p={4}
          pb={2}
          color={colorMode === "dark" ? "black" : "black"}
          borderRadius="md"
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          cursor="pointer"
        >
          <Text fontSize="md" fontWeight="semibold" mb={0}>
            Campaign Cluster
          </Text>
          <ChevronDownIcon />
        </Box>
        <Collapse in={isDropdownOpen}>
          <Accordion allowMultiple mt={0}>
            {programs
              .filter(
                (programObj) => programObj.name && programObj.name.trim() !== ""
              )
              .map((programObj) => {
                const isSelected =
                  programObj._id ===
                  (selectedProgram ? selectedProgram._id : null);
                return (
                  <AccordionItem key={programObj._id} border="none">
                    <HStack spacing={4} position="relative">
                      {isSelected && (
                        <Box
                          position="absolute"
                          right={0}
                          top={0}
                          bottom={0}
                          width="3px"
                          // bg="#FEA130"
                          bg="#0F123F"
                          borderRadius="md"
                        />
                      )}
                      <AccordionButton
                        onClick={() => fetchSelectedProgram(programObj._id)}
                        _hover={{ bg: "transparent" }}
                        _focus={{ boxShadow: "none" }}
                      >
                        <Text
                          mb={0}
                          ml={2}
                          color={isSelected ? "gray.900" : "black"}
                          fontWeight={isSelected ? "bold" : "normal"}
                        >
                          {programObj.name}
                        </Text>
                      </AccordionButton>
                      <IconButton
                        variant="ghost"
                        aria-label={`Delete ${programObj.name}`}
                        icon={<DeleteIcon />}
                        color="black"
                        onClick={() => {
                          if (
                            window.confirm(
                              `Are you sure you want to delete the Program: ${programObj.name}?`
                            )
                          ) {
                            handleRemoveProgram(programObj);
                          }
                        }}
                        size="xs"
                        mr={6}
                      />
                    </HStack>
                  </AccordionItem>
                );
              })}
          </Accordion>
        </Collapse>
      </Box>

      {/* Bottom VStack: Always at the Bottom */}
      <VStack
        pl={4}
        pr={4}
        pb={2}
        spacing={2}
        mt="auto" // Push this VStack to the bottom
      >
        {programs.length !== 0 && (
          <Box w="100%">
            <CreateButton
              showInputField={showInputField}
              setShowInputField={setShowInputField}
              currentProgram={currentProgram}
              setCurrentProgram={setCurrentProgram}
              handleAddProgram={handleAddProgram}
              isAdding={isAdding}
              setIsAdding={setIsAdding}
              width="100%"
              color="black"
            />
          </Box>
        )}

        {/* Divider with custom color */}
        {programs.length !== 0 && <Divider borderColor="#0F123F" m={0} />}
        {/* Company Name */}
        {company_name && company_name !== "null" && (
          <Heading
            fontWeight="bold"
            fontSize="lg"
            color="black"
            noOfLines="1"
            textAlign="center"
            mb={0}
          >
            {company_name}
          </Heading>
        )}
      </VStack>
    </Box>
  );
};

export default Program_CRUD;
