import React, { useState, useEffect } from "react";
import {
  Box,
  SimpleGrid,
  Heading,
  Text,
  Container,
  VStack,
  Button,
  Switch,
  FormControl,
  FormLabel,
  useColorModeValue,
  Icon,
  Flex,
  HStack,
  Input,
  Alert,
  AlertIcon,
  AlertDescription,
  AlertTitle,
  List,
  ListItem,
  ListIcon,
  Tag,
} from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { FiZap } from "react-icons/fi";
import { useHistory } from "react-router-dom"; // Import useNavigate
import Navbar from "./HomePage/Navbar";
import Footer from "./HomePage/Footer";
import Logo from "../assets/images/logo-home-dark.png";

const PricingPage = () => {
  const [isAnnual, setIsAnnual] = useState(true);
  const history = useHistory(); // Initialize useNavigate
  const [seatCount, setSeatCount] = useState(5); // Seat count for Teams plan
  const incrementSeats = () => setSeatCount((prev) => Math.min(prev + 1, 100));
  const decrementSeats = () => setSeatCount((prev) => Math.max(prev - 1, 5));

  // Prices
  const freePrice = 0;
  const individualMonthlyPrice = 25;
  const individualAnnualPrice = 240;
  const teamsMonthlyPrice = seatCount * 30;
  const teamsAnnualPrice = seatCount * 25 * 12;

  // Colors & styling
  const pageBg = useColorModeValue("white", "white");
  const cardBg = useColorModeValue("white", "gray.800");
  const highlightCardBg = useColorModeValue("#08072B", "green.900");
  const highlightTextColor = useColorModeValue("white", "white");
  const bg = useColorModeValue("gray.200", "gray.700");
  const activeBg = useColorModeValue("white", "gray.900");
  // Billing period
  const billingPeriod = isAnnual ? "year" : "monthly";

  // Handle navigation to signup page
  const handleGetStarted = (planType) => {
    const queryParams = new URLSearchParams({
      plan_type: planType,
      subscription_duration: billingPeriod,
    });

    if (planType === "teams") {
      queryParams.append("seat_count", seatCount);
    }

    history.push(`/signup?${queryParams.toString()}`);
  };

  
  useEffect(() => {
    const loadCalendlyScript = () => {
      const script = document.createElement("script");
      script.src = "https://assets.calendly.com/assets/external/widget.js";
      script.async = true;
      document.body.appendChild(script);

      script.onload = () => {
        console.log("Calendly script loaded!");
      };
    };

    // Load Calendly script if it's not already loaded
    if (!window.Calendly) {
      loadCalendlyScript();
    }
  }, []);

  const openCalendly = () => {
    if (window.Calendly) {
      // Disable body scroll
      document.body.style.overflow = "hidden";

      window.Calendly.initPopupWidget({
        url: "https://calendly.com/bryan-smart-focus",
      });

      // Apply custom styles to Calendly modal
      const checkCalendlyOpen = setInterval(() => {
        const calendlyPopup = document.querySelector(".calendly-popup");
        if (calendlyPopup) {
          calendlyPopup.style.height = "100vh"; // Set height
          calendlyPopup.style.backgroundColor = "white"; // Change background color
          calendlyPopup.style.borderRadius = "12px"; // Optional: rounded corners
          calendlyPopup.style.boxShadow = "0px 10px 30px rgba(0,0,0,0.2)"; // Optional: Add shadow
          calendlyPopup.style.overflowY = "hidden";
          clearInterval(checkCalendlyOpen);
        }
      }, 500);

      // Restore scrolling when Calendly closes
      const checkCalendlyClose = setInterval(() => {
        if (!document.querySelector(".calendly-popup")) {
          document.body.style.overflowY = "auto"; // Re-enable scrolling
          clearInterval(checkCalendlyClose);
        }
      }, 500);
    } else {
      console.error("Calendly script not loaded yet");
    }
  };

  return (
    <Box bg={pageBg} w="100%" minH="100vh" position="relative">
      {/* Navbar */}
      <Navbar Logo={Logo} />

      <Container maxW="7xl" py={10} pt={{ base: 20, md: 20, lg: 28 }}>
        {/* Title & Description */}
        <VStack
          textAlign="center"
          spacing={2}
          mt={{ base: 10, md: 14, lg: 14 }}
          mb={4}
        >
          <Heading size="2xl" fontWeight="bold" mb={0}>
            Pricing
          </Heading>
          <Text color="gray.500" fontSize="lg" maxW="3xl" mb={0}>
            Our pricing plans are simple and designed to cater to various needs.
            Choose a plan that suits you best.
          </Text>
          <Alert
            // status="info"
            // variant="left-accent"
            rounded="md"
            mb={3}
            // bg="gray.100"
            bg="none"
            // boxShadow="md"
            // border="1px solid"
            // borderColor="blue.300"
            justifyContent="center"
            textAlign="center"
          >
            <AlertDescription fontSize="sm" color="gray.800" textAlign="center">
              {/* Credit explanation */}
              <Box mb={1}>
                <Text as="span" fontWeight="bold" color="blue.600" mb={0}>
                  1 Credit
                </Text>{" "}
                ={" "}
                <Text as="span" fontWeight="bold" color="green.600" mb={0}>
                  1 Company
                </Text>{" "}
                or{" "}
                <Text as="span" fontWeight="bold" color="green.600" mb={0}>
                  1 Senior Exec Prospect
                </Text>
              </Box>

              {/* Outreach Tools Subheading */}
              <Text fontWeight="bold" mb={1}>
                Included with Each Credit
              </Text>

              {/* Tags */}
              <HStack spacing={2} wrap="wrap" justifyContent="center">
                {/* Email Items (blue) */}
                <Tag colorScheme="blue" size="sm" fontWeight="bold" borderRadius="full">
                  Follow-up emails
                </Tag>
                <Tag colorScheme="blue" size="sm" fontWeight="bold" borderRadius="full">
                  Initial email outreach
                </Tag>

                {/* LinkedIn Items (green) */}
                <Tag colorScheme="green" size="sm" fontWeight="bold" borderRadius="full">
                  LinkedIn connection note
                </Tag>
                <Tag colorScheme="green" size="sm" fontWeight="bold" borderRadius="full">
                  LinkedIn message
                </Tag>

                {/* Cold-calling Script (orange) */}
                <Tag colorScheme="orange" size="sm" fontWeight="bold" borderRadius="full">
                  Cold-calling script
                </Tag>
              </HStack>
            </AlertDescription>
          </Alert>
        </VStack>

        {/* Toggle: Monthly / Annual */}
        <Flex
          alignItems="center"
          justifyContent="center"
          mb={14}
          direction="column"
        >
          {/* Segmented control container */}
          <Box
            display="inline-flex"
            alignItems="center"
            borderRadius="xl"
            bg={bg}
            p="1"
            // px={4}
          >
            {/* Monthly Button */}
            <Button
              size="sm"
              variant="unstyled"
              borderRadius="xl"
              px={{ base: 4, md: 10, lg: 10 }}
              py={2}
              fontWeight="bold"
              bg={!isAnnual ? activeBg : "transparent"}
              color={!isAnnual ? "blue.600" : "gray.500"}
              onClick={() => setIsAnnual(false)}
            >
              Monthly
            </Button>
            <HStack
              bg={isAnnual ? activeBg : "transparent"}
              borderRadius="xl"
              spacing={0}
            >
              {/* Annually Button */}
              <Button
                size="sm"
                variant="unstyled"
                px={{ base: 4, md: 10, lg: 10 }}
                py={2}
                pr={
                  !isAnnual
                    ? { base: 4, md: 10, lg: 10 }
                    : { base: 2, md: 2, lg: 2 }
                }
                fontWeight="bold"
                color={isAnnual ? "blue.600" : "gray.500"}
                onClick={() => setIsAnnual(true)}
              >
                Annually
              </Button>
              {/* Badge shown if Annual is selected */}
              {isAnnual && (
                <Box
                  mr={1}
                  bg={bg}
                  color="black"
                  fontSize="10px"
                  size="sm"
                  fontWeight="medium"
                  px={2}
                  py={1}
                  borderRadius="xl"
                >
                  Save Up-to 20%
                </Box>
              )}
            </HStack>
          </Box>
        </Flex>

        {/* Pricing Cards in a Responsive Grid */}
        <SimpleGrid
          columns={{ base: 1, md: 2, lg: 4 }}
          spacing={{ base: 6, md: 4 }}
        >
          {/* Free Plan */}
          <Box
            textAlign="left"
            style={{
              filter: isAnnual ? "blur(3px)" : "none",
            }}
          >
            <VStack align="flex-start" spacing={3}>
              <Box
                bg={cardBg}
                border="1px solid"
                borderColor="blackAlpha.300"
                boxShadow="lg"
                rounded="xl"
                p={6}
              >
                <Icon as={FiZap} boxSize={8} color="green.400" mb={4} />
                <HStack>
                  <Heading size="md" fontWeight="bold" mb={0}>
                    Free
                  </Heading>
                  <Text fontSize="sm" color="gray.500" mb={0}>
                    (No Credit Card Required)
                  </Text>
                </HStack>

                <Box display="flex" alignItems="baseline">
                  <Text fontSize="4xl" fontWeight="bold" mr={1}>
                    ${freePrice}
                  </Text>
                  <Text color="gray.500">/ {billingPeriod}</Text>
                </Box>
                <Text color="gray.600">
                  Ideal for personal projects or trying out our service
                  completely free. Includes basic support and up to 5
                  personalized pieces of content per prospect or company.
                </Text>
                <Button
                  mt={4}
                  colorScheme="#08072B"
                  variant="outline"
                  rounded="full"
                  w="full"
                  isDisabled={isAnnual}
                  onClick={() => handleGetStarted("free")}
                >
                  Get started
                </Button>
              </Box>
              {/* <Divider mt={4} /> */}
              <Box mt={4}>
                <Text fontWeight="semibold" mb={2}>
                  What's included
                </Text>
                <List spacing={2} pl={0}>
                  <ListItem>
                    <ListIcon as={CheckCircleIcon} color="#08072B" />
                    50 Credits Per Month
                  </ListItem>
                  <ListItem>
                    <ListIcon as={CheckCircleIcon} color="#08072B" />
                    Basic Support
                  </ListItem>
                  <ListItem>
                    <ListIcon as={CheckCircleIcon} color="#08072B" />
                    Upto 5 pieces of content per prospect or company
                  </ListItem>
                </List>
              </Box>
            </VStack>
          </Box>

          {/* Individual Plan */}
          <Box textAlign="left">
            <VStack align="flex-start" spacing={3}>
              <Box
                bg={cardBg}
                border="1px solid"
                borderColor="blackAlpha.300"
                boxShadow="lg"
                rounded="xl"
                p={6}
              >
                <Icon as={FiZap} boxSize={8} color="green.400" mb={4} />
                <Heading size="md" fontWeight="bold" mb={0}>
                  Individual
                </Heading>
                <Box display="flex" alignItems="baseline">
                  <Text fontSize="4xl" fontWeight="bold" mr={1}>
                    ${isAnnual ? individualAnnualPrice : individualMonthlyPrice}
                  </Text>
                  {isAnnual && (
                    <Text
                      fontSize="sm"
                      color="gray.500"
                      textDecoration="line-through"
                      mr={2}
                    >
                      ${individualMonthlyPrice * 12}
                    </Text>
                  )}
                  <Text color="gray.500">/ {billingPeriod}</Text>
                </Box>
                <Text color="gray.600">
                  Best for freelancers or professionals looking to secure
                  executive-level meetings with personalized outreach content,
                  tailored for bespoke campaigns.
                </Text>
                <Button
                  mt={4}
                  bg="#08072B"
                  color="white"
                  _hover="none"
                  _active="none"
                  rounded="full"
                  w="full"
                  onClick={() => handleGetStarted("individual")}
                  isDisabled
                >
                  {/* Get started */}
                  Coming Soon
                </Button>
              </Box>
              {/* <Divider mt={4} /> */}
              <Box mt={4}>
                <Text fontWeight="semibold" mb={2}>
                  What's included
                </Text>
                <List spacing={2} pl={0}>
                  <ListItem>
                    <ListIcon as={CheckCircleIcon} color="#08072B" />
                    750 Credits Per Month
                  </ListItem>
                  <ListItem>
                    <ListIcon as={CheckCircleIcon} color="#08072B" />
                    Premium Support
                  </ListItem>
                  <ListItem>
                    <ListIcon as={CheckCircleIcon} color="#08072B" />8 Upto 5
                    pieces of content per prospect or company
                  </ListItem>
                </List>
              </Box>
            </VStack>
          </Box>

          {/* Teams Plan */}
          <Box textAlign="left">
            <VStack align="flex-start" spacing={3}>
              <Box
                bg={cardBg}
                border="1px solid"
                borderColor="blackAlpha.300"
                boxShadow="lg"
                rounded="xl"
                p={6}
              >
                <Icon as={FiZap} boxSize={8} color="green.400" mb={4} />
                <HStack align="bottom" justifyContent="space-between">
                  <Heading size="md" fontWeight="bold" mb={0}>
                    Teams
                  </Heading>
                  <HStack justifyContent="center" spacing={1} h="24px">
                    <Button onClick={decrementSeats} size="sm" bg="none">
                      -
                    </Button>
                    <Input
                      value={seatCount}
                      readOnly
                      textAlign="center"
                      size="xs"
                      w="30%"
                    />
                    <Button onClick={incrementSeats} size="sm" bg="none">
                      +
                    </Button>
                  </HStack>
                </HStack>
                <Box display="flex" alignItems="baseline">
                  <Text fontSize="4xl" fontWeight="bold" mr={1}>
                    ${isAnnual ? teamsAnnualPrice : teamsMonthlyPrice}
                  </Text>
                  {isAnnual && (
                    <Text
                      fontSize="sm"
                      color="gray.500"
                      textDecoration="line-through"
                      mr={2}
                    >
                      ${teamsMonthlyPrice * 12}
                    </Text>
                  )}
                  <Text color="gray.500">/ {billingPeriod}</Text>
                </Box>
                <Text color="gray.600">
                  Perfect for businesses or teams aiming to achieve
                  executive-level meetings with advanced outreach. Includes
                  pooled credits and premium support.
                </Text>
                <Button
                  mt={4}
                  colorScheme="#08072B"
                  variant="outline"
                  rounded="full"
                  w="full"
                  onClick={() => handleGetStarted("teams")}
                  isDisabled
                >
                  {/* Get started */}
                  Coming Soon
                </Button>
              </Box>
              {/* <Divider mt={4} /> */}
              <Box mt={4}>
                <Text fontWeight="semibold" mb={2}>
                  What's included
                </Text>
                <List spacing={2} pl={0}>
                  <ListItem>
                    <ListIcon as={CheckCircleIcon} color="#08072B" />
                    1250 Credits Per Month per seat
                  </ListItem>
                  <ListItem>
                    <ListIcon as={CheckCircleIcon} color="#08072B" />
                    Premium Support
                  </ListItem>
                  <ListItem>
                    <ListIcon as={CheckCircleIcon} color="#08072B" />
                    Credits pooled across the team
                  </ListItem>
                  <ListItem>
                    <ListIcon as={CheckCircleIcon} color="#08072B" />
                    Upto 5 pieces of content per prospect or company
                  </ListItem>
                </List>
              </Box>
            </VStack>
          </Box>

          {/* Custom / Enterprise Plan */}
          <Box textAlign="left">
            <VStack align="flex-start" spacing={3}>
              <Box
                bg={highlightCardBg}
                color={highlightTextColor}
                border="1px solid"
                borderColor="blackAlpha.300"
                boxShadow="lg"
                rounded="xl"
                p={6}
              >
                <Icon as={FiZap} boxSize={8} color="green.400" mb={4} />
                <Heading size="md" fontWeight="bold" mb={0}>
                  Enterprise
                </Heading>
                <Box display="flex" alignItems="baseline">
                  <Text fontSize="4xl" fontWeight="bold" mr={1}>
                    Custom
                  </Text>
                </Box>
                <Text color="whiteAlpha.900">
                  Tailored for organizations with unique requirements or
                  high-volume needs. Offers advanced analytics, customizable
                  options, and dedicated support.
                </Text>
                <Button
                  mt={4}
                  colorScheme="gray"
                  bg="white"
                  color="#08072B"
                  _hover="none"
                  _active="none"
                  rounded="full"
                  w="full"
                  onClick={openCalendly}
                  // isDisabled
                >
                  Contact us
                  {/* Coming Soon */}
                </Button>
              </Box>
              {/* <Divider mt={4} borderColor="whiteAlpha.400" /> */}
              <Box mt={4}>
                <Text fontWeight="semibold" mb={2}>
                  What's included
                </Text>
                <List spacing={2} pl={0}>
                  <ListItem>
                    <ListIcon as={CheckCircleIcon} color="lime.400" />
                    Fully customizable options
                  </ListItem>
                  <ListItem>
                    <ListIcon as={CheckCircleIcon} color="lime.400" />
                    Dedicated technical support
                  </ListItem>
                  <ListItem>
                    <ListIcon as={CheckCircleIcon} color="lime.400" />
                    Advanced analytics & 24/7 priority support
                  </ListItem>
                </List>
              </Box>
            </VStack>
          </Box>
        </SimpleGrid>
      </Container>

      {/* Footer (optional) */}
      <Footer />
    </Box>
  );
};

export default PricingPage;
