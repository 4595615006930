import React, { useState } from "react";
import axiosInstance from "../../../helpers/axiosInstance";
import {
  Text,
  Tr,
  Td,
  IconButton,
  Button,
  Spinner,
  useToast,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  HStack,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import {
  FiSettings,
  FiEye,
  FiPlay,
  FiStopCircle,
  FiRefreshCw,
  FiPauseCircle,
} from "react-icons/fi";
import { DeleteIcon } from "@chakra-ui/icons";
import { useHistory } from "react-router-dom";
import OutreachProfileDropdown from "../../common/OutreachProfileDropdown";
import SenderProfileDropdown from "../../common/SenderProfileDropdown";
import {
  fetchStatusByExecutionId,
  stopExecution,
  retryContent,
  reExecuteContent,
} from "../../api/Program/contentApi";

const MotionIcon = motion(FiRefreshCw);

const TableRow = ({
  row,
  progressUpdate, // from WebSocket, keyed by row.file_unique_id
  formatDate,
  onUploadOpen,
  handleOpenSettings,
  confirmDelete,
  isDeleting,
  selectedProgramId,
  fetchSelectedProgram,
  executionId,
  selectedProgram,
  isGenerating, // global flag (true if any row is generating)
  generatingRowId, // the file_unique_id of the row that is generating
  setIsGenerating,
}) => {
  const toast = useToast();
  const history = useHistory();

  // Local loading states for each action:
  const [loadingGenerateId, setLoadingGenerateId] = useState(null);
  const [loadingRetryId, setLoadingRetryId] = useState(null);
  const [loadingReExecuteId, setLoadingReExecuteId] = useState(null);
  const [loadingStopId, setLoadingStopId] = useState(null);

  // Determine if this row is generating via a local call:
  const isRowGenerating = loadingGenerateId === row.file_unique_id;

  // Define final statuses:
  const finalStatuses = ["STOPPED", "OUT_OF_CREDITS", "COMPLETED", "FAILED"];
  const isNotFinalStatus = (status) =>
    status && !finalStatuses.includes(status);

  // Spinner conditions now remain active as long as the status is not final.
  const generateLoading =
    loadingGenerateId === row.file_unique_id ||
    (progressUpdate?.type === "EXECUTE" &&
      isNotFinalStatus(progressUpdate?.status));

  // const generateLoading =
  //   loadingGenerateId === row.file_unique_id;

  const retryLoading =
    loadingRetryId === row.file_unique_id ||
    (progressUpdate?.type === "RETRYING" &&
      isNotFinalStatus(progressUpdate?.status));

  // const retryLoading =
  //   loadingRetryId === row.file_unique_id;

  const reexecuteLoading =
    loadingReExecuteId === row.file_unique_id ||
    (progressUpdate?.type === "REEXECUTE" &&
      isNotFinalStatus(progressUpdate?.status));

  // const reexecuteLoading =
  //   loadingReExecuteId === row.file_unique_id;

  // Combined spinner state for the Menu Button
  const menuButtonLoading = generateLoading || retryLoading || reexecuteLoading;

  // ---------------------------------------------------------------------------------
  // Compute per-menu-item disabled states.
  // 1. Generate Content is disabled when an execution already exists.
  const disableGenerateMenuItem = Boolean(executionId);

  // 2. Resume/Retry is disabled if there’s no execution or the execution is complete.
  const isExecutionComplete = (generatedResults) => {
    if (!generatedResults || generatedResults === "N/A") return false;
    const [completed, total] = generatedResults.split("/").map(Number);
    return completed === total;
  };
  const disableRetryMenuItem =
    !row.executions ||
    row.executions.length === 0 ||
    isExecutionComplete(row.executions[0]?.generated_results);

  // 3. Re-Execute is disabled if there’s no execution.
  const disableReexecuteMenuItem =
    !row.executions || row.executions.length === 0;

  // ---------------------------------------------------------------------------------
  // Compute overall menu button disabled state.
  // Disable if some other row is generating or if all actions are disabled.
  const allMenuItemsDisabled =
    disableGenerateMenuItem && disableRetryMenuItem && disableReexecuteMenuItem;
  const isMenuButtonDisabled =
    (isGenerating && generatingRowId !== row.file_unique_id) ||
    allMenuItemsDisabled;

  // ---------------------------------------------------------------------------------
  // Handlers for actions:
  const handleGenerateContent = async (row) => {
    try {
      setLoadingGenerateId(row.file_unique_id);
      const payload = {
        program_id: selectedProgram._id,
        file_unique_id: row.file_unique_id,
      };
      const response = await axiosInstance.post(
        "/content-generation/generate-content",
        payload
      );
      if (response.status === 200) {
        fetchSelectedProgram(selectedProgram._id);
        toast({
          title: "Content Generation",
          description: response.data.message,
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "top-right",
        });
      }
    } catch (error) {
      console.error("Error generating content:", error);
      toast({
        title: "Error",
        description:
          error.message || "An error occurred while generating content.",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
    } finally {
      setLoadingGenerateId(null);
    }
  };

  const handleRetryContent = async () => {
    try {
      setLoadingRetryId(row.file_unique_id);
      const data = await retryContent(
        selectedProgramId,
        row.file_unique_id,
        executionId
      );
      toast({
        title: "Retry Started",
        description: data.message,
        status: "success",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
    } catch (error) {
      console.error("Error retrying content:", error);
      toast({
        title: "Error",
        description:
          error.message || "An error occurred while retrying content.",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
    } finally {
      setLoadingRetryId(null);
    }
  };

  const handleReExecuteContent = async () => {
    try {
      setLoadingReExecuteId(row.file_unique_id);
      const data = await reExecuteContent(
        selectedProgramId,
        row.file_unique_id,
        executionId
      );
      toast({
        title: "Re-Execution Started",
        description: data.message,
        status: "success",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
    } catch (error) {
      console.error("Error re-executing content:", error);
      toast({
        title: "Error",
        description:
          error.message || "An error occurred while re-executing content.",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
    } finally {
      setLoadingReExecuteId(null);
    }
  };

  const handleStopExecution = async () => {
    try {
      setLoadingStopId(row.file_unique_id);
      const data = await stopExecution(selectedProgramId, row.file_unique_id);
      toast({
        title: "Execution Stopped",
        description: data.message,
        status: "success",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
    } catch (error) {
      console.error("Error stopping execution:", error);
      toast({
        title: "Error",
        description:
          error.message || "An error occurred while stopping execution.",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
    } finally {
      setLoadingStopId(null);
    }
  };

  const handleOutreachChange = async () => {
    await fetchSelectedProgram(selectedProgramId);
  };

  const handleSenderChange = async () => {
    await fetchSelectedProgram(selectedProgramId);
  };

  const handleViewDetails = () => {
    history.push(
      `/program/generation/execution/${selectedProgramId}/${row.file_unique_id}/${executionId}`
    );
  };

  const viewButtonDisabled =
    (!row.executions || row.executions.length === 0) &&
    !(generateLoading || retryLoading || reexecuteLoading);

  return (
    <Tr>
      <Td textAlign="left">{formatDate(row.data_time)}</Td>

      <Td textAlign="center">
        <OutreachProfileDropdown
          selectedValue={row.outreach_profile_id || ""}
          programId={selectedProgramId}
          fileUniqueId={row.file_unique_id}
          onChange={handleOutreachChange}
        />
      </Td>

      <Td textAlign="center">
        <SenderProfileDropdown
          selectedValue={row.sender_profile_id || ""}
          programId={selectedProgramId}
          fileUniqueId={row.file_unique_id}
          onChange={handleSenderChange}
        />
      </Td>

      <Td textAlign="center">
        <IconButton
          icon={<FiSettings />}
          size="sm"
          aria-label="Settings"
          bg="transparent"
          onClick={() => handleOpenSettings(row)}
        />
      </Td>

      <Td textAlign="center">
        <Button
          onClick={onUploadOpen}
          bg="#231F1F"
          color="#FFFFFF"
          _hover={{ bg: "#404040" }}
          borderRadius="full"
          size="xs"
          mr={3}
        >
          Add
        </Button>
      </Td>

      <Td textAlign="left">{row.filename}</Td>

      {/* ACTIONS DROPDOWN */}
      <Td textAlign="center">
        <Menu>
          <MenuButton
            as={IconButton}
            icon={menuButtonLoading ? <Spinner size="sm" /> : <FiPlay />}
            size="sm"
            aria-label="Actions"
            bg="transparent"
            isDisabled={isMenuButtonDisabled}
          />
          <MenuList>
            <MenuItem
              icon={generateLoading ? <Spinner size="sm" /> : <FiPlay />}
              onClick={() => handleGenerateContent(row)}
              isDisabled={disableGenerateMenuItem && !isRowGenerating}
            >
              Generate Content
            </MenuItem>

            {row.executions && row.executions[0] && (
              <MenuItem
                icon={retryLoading ? <Spinner size="sm" /> : <FiPauseCircle />}
                onClick={() => handleRetryContent(executionId)}
                isDisabled={disableRetryMenuItem}
              >
                Resume and Finish
              </MenuItem>
            )}

            {row.executions && row.executions[0] && (
              <MenuItem
                icon={
                  reexecuteLoading ? <Spinner size="sm" /> : <FiRefreshCw />
                }
                onClick={() => handleReExecuteContent(executionId)}
                isDisabled={disableReexecuteMenuItem}
              >
                Re-Execute
              </MenuItem>
            )}
          </MenuList>
        </Menu>
      </Td>

      {/* STATUS */}
      <Td textAlign="center">
        <Text size="sm" mb={0}>
          {progressUpdate?.computedProgress ||
            (row.executions && row.executions[0]?.progress) ||
            (row.executions && row.executions[0]?.status) ||
            "--"}
        </Text>
      </Td>

      {/* STOP EXECUTION */}
      <Td textAlign="center">
        <IconButton
          icon={
            loadingStopId === row.file_unique_id ? (
              <Spinner size="sm" />
            ) : (
              <FiStopCircle />
            )
          }
          color="red"
          size="sm"
          aria-label="Stop"
          bg="transparent"
          onClick={() => handleStopExecution(executionId)}
        />
      </Td>

      {/* VIEW RESULT */}
      <Td textAlign="center">
        <IconButton
          icon={<FiEye />}
          size="sm"
          aria-label="View Result"
          bg="transparent"
          onClick={handleViewDetails}
          isDisabled={viewButtonDisabled}
        />
      </Td>

      {/* DELETE ROW */}
      <Td textAlign="center">
        <IconButton
          icon={<DeleteIcon />}
          size="sm"
          aria-label="Delete"
          bg="transparent"
          onClick={() => confirmDelete(row)}
          // isLoading={isDeleting}
        />
      </Td>
    </Tr>
  );
};

export default TableRow;
