import React from "react";
import {
  Box,
  VStack,
  Heading,
  Text,
  Button,
  List,
  ListItem,
  ListIcon,
  Icon,
  HStack,
  Input,
} from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";

const PlanCard = ({
  title,
  price,
  period,
  description,
  features,
  bg,
  buttonbg,
  color,
  icon,
  onClick,
  isDisabled,
  buttonText,
  seatIncrement,
  seatDecrement,
  seatCount,
  plan,
  style,
  isAnnual,
}) => {
  return (
    <Box textAlign="left" style={style}>
      <VStack align="flex-start" spacing={3}>
        <Box
          bg={bg}
          border="1px solid"
          borderColor="blackAlpha.300"
          boxShadow="lg"
          rounded="xl"
          p={6}
        >
          <Icon as={icon} boxSize={8} color="green.400" mb={4} />
          <HStack alignContent="bottom" justifyContent="space-between">
            <HStack>
              <Heading size="md" fontWeight="bold" mb={0}>
                {title}
              </Heading>
              {plan.title === "Free" && (
                <Text fontSize="sm" color="gray.500" mb={0}>
                  (No Credit Card Required)
                </Text>
              )}
            </HStack>
            {title === "Teams" && (
              <HStack justifyContent="center" spacing={1} h="24px">
                <Button onClick={seatDecrement} size="sm" bg="none">
                  -
                </Button>
                <Input
                  value={seatCount}
                  readOnly
                  textAlign="center"
                  size="xs"
                />
                <Button onClick={seatIncrement} size="sm" bg="none">
                  +
                </Button>
              </HStack>
            )}
          </HStack>
          <Box display="flex" alignItems="baseline">
            <Text fontSize="4xl" fontWeight="bold" mr={1}>
              {price}
            </Text>
            {plan.originalPrice && (
              <Text fontSize="sm" color="gray.500" textDecoration="line-through" mr={2}>
                {plan.originalPrice}
              </Text>
            )}
            <Text color="gray.500">/ {period}</Text>
          </Box>
          <Text color="gray.600">{description}</Text>

          <Button
            mt={4}
            bg={buttonbg}
            color={color}
            _hover="none"
            _active="none"
            borderColor="#08072B"
            variant="outline"
            rounded="full"
            w="full"
            isDisabled={isDisabled || (isAnnual && plan.title === "Free")}
            onClick={onClick}
          >
            {buttonText}
          </Button>
        </Box>

        <Box mt={4}>
          <Text fontWeight="semibold" mb={2}>
            What's included
          </Text>
          <List spacing={2} pl={0}>
            {features.map((feature, index) => (
              <ListItem key={index}>
                <ListIcon as={CheckCircleIcon} color="#08072B" />
                {feature}
              </ListItem>
            ))}
          </List>
        </Box>
      </VStack>
    </Box>
  );
};

export default PlanCard;
