import React, { useState, useEffect } from "react";
import {
  Box,
  HStack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useToast,
  Heading,
  IconButton,
  Spacer,
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useColorMode,
  Flex,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { FiArrowLeft } from "react-icons/fi";
import axiosInstance from "../../helpers/axiosInstance";
import UserProfileMenu from "../UserProfileMenu";
import ViewDetailsPagination from "../Program/MiddlePannel/Generation/ViewDetailsPagination";
import AddSenderModal from "./AddSenderModal";
import { useUser } from "../userRole/UserContext";

function SenderInformation() {
  const { colorMode } = useColorMode();
  const history = useHistory();
  const toast = useToast();

  const [profiles, setProfiles] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [fileCount, setFileCount] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // Removed: const [profileToEdit, setProfileToEdit] = useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [profileToDelete, setProfileToDelete] = useState(null);
  const { user } = useUser();
  const role = user ? user.role : null;

  const [selectedProfile, setSelectedProfile] = useState(null);

  useEffect(() => {
    fetchSenderProfile();
  }, []);

  const fetchSenderProfile = async () => {
    try {
      const response = await axiosInstance.get("/sender_profile/");
      if (response.status === 200 && response.data.success) {
        setProfiles(response.data.data.profiles || []); // Ensure profiles is always an array
        // Assuming totalPages is calculated based on profiles and fileCount
        const calculatedTotalPages = Math.ceil(
          (response.data.data.profiles || []).length / fileCount
        );
        setTotalPages(calculatedTotalPages);
      }
    } catch (error) {
      console.error("Error fetching profiles:", error);
      toast({
        title: "Error",
        description: "An error occurred while fetching profiles.",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const handleDeleteProfile = async () => {
    if (profileToDelete) {
      try {
        const response = await axiosInstance.delete(
          `/sender_profile/${profileToDelete._id}`
        );
        if (response.status === 200) {
          toast({
            title: "Profile Deleted",
            description: response.data.message,
            status: "success",
            duration: 2000,
            isClosable: true,
            position: "top-right",
          });
          setProfiles((prevProfiles) =>
            prevProfiles.filter(
              (profile) => profile._id !== profileToDelete._id
            )
          );
        }
      } catch (error) {
        console.error("Error deleting profile:", error);
        toast({
          title: "Error",
          description: "An error occurred while deleting the profile.",
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "top-right",
        });
      }
      setIsDeleteDialogOpen(false);
    }
  };

  const confirmDeleteProfile = (profile) => {
    setProfileToDelete(profile);
    setIsDeleteDialogOpen(true);
  };

  const handleEditProfile = async (profile) => {
    try {
      const response = await axiosInstance.get(
        `/sender_profile/${profile._id}`
      );
      if (response.status === 200 && response.data.success) {
        setSelectedProfile(response.data.data);
        setIsModalOpen(true);
      }
    } catch (error) {
      console.error("Error fetching profile:", error);
      toast({
        title: "Error",
        description: "An error occurred while fetching the profile.",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const onOpenModal = () => {
    setSelectedProfile(null); // Reset selectedProfile to null for adding a new profile
    setIsModalOpen(true);
  };

  const onCloseModal = () => setIsModalOpen(false);

  const handleSave = () => {
    fetchSenderProfile(); // Refresh profiles after saving
  };

  const goToHomePage = () => {
    history.push("/program");
  };

  return (
    <Box
      width={{ base: "100%", md: "100%" }}
      height="100vh"
      display="flex"
      alignItems="top"
      justifyContent="top"
    >
      <Box
        direction="column"
        height="100vh"
        width={{ base: "100%", md: "100%" }}
      >
        <Box
          // width={{ base: "100%", md: "100%" }}
          height="15vh"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          p={1}
          pt={10}
          pl={6}
          pr={5}
        >
          {/* Left side: Sender Profiles */}
          <HStack>
            <IconButton
              icon={<FiArrowLeft />}
              bg="transparent"
              onClick={() => history.goBack()}
              aria-label="Back"
              _hover="none"
              size="lg"
            />
            <Heading fontWeight="bold" mb={0} color="black">
              Sender Profiles
            </Heading>
          </HStack>

          {/* Right side: Add Profile & UserProfileMenu */}
          <HStack spacing={2}>
            {role !== "Employee" && (
              <Button
                bg="#231F1F"
                color="#FFFFFF"
                _hover={{ bg: "#404040" }}
                borderRadius="full"
                size="sm"
                onClick={onOpenModal}
              >
                Add Profile
              </Button>
            )}
            <UserProfileMenu />
          </HStack>
        </Box>

        <Box p={5} pl={8} pr={8} pb={2} h="80vh">
          <Box
            width={{ base: "100%", md: "100%" }}
            h="100%"
            bg="white"
            borderRadius="xl"
            p={3}
            pb={1}
            borderColor="blackAlpha.200"
            borderWidth="1px"
          >
            <Box h="100%" overflowY="auto">
              <Table variant="simple" size="sm">
                <Thead>
                  <Tr>
                    <Th>Sender Name</Th>
                    <Th>Title</Th>
                    <Th>Company Name</Th> {/* New column for company name */}
                    <Th>Meeting Link</Th>
                    {role !== "Employee" && <Th pl={0}>Actions</Th>}
                  </Tr>
                </Thead>
                <Tbody>
                  {profiles
                    .slice(
                      (currentPage - 1) * fileCount,
                      currentPage * fileCount
                    )
                    .map((profile) => (
                      <Tr key={profile._id}>
                        <Td>{profile.sender_name}</Td>
                        <Td>{profile.sender_title}</Td>
                        <Td>{profile.sender_company_name}</Td>
                        <Td>{profile.meeting_link}</Td>
                        {/* New cell for company name */}
                        {role !== "Employee" && (
                          <Td pl={0}>
                            <Flex>
                              <IconButton
                                icon={<EditIcon />}
                                colorScheme="blue"
                                aria-label="Edit Profile"
                                borderRadius="full"
                                size="sm"
                                onClick={() => handleEditProfile(profile)}
                              />
                              <IconButton
                                icon={<DeleteIcon />}
                                colorScheme="red"
                                aria-label="Delete Profile"
                                borderRadius="full"
                                size="sm"
                                onClick={() => confirmDeleteProfile(profile)}
                                ml={2}
                              />
                            </Flex>
                          </Td>
                        )}
                      </Tr>
                    ))}
                </Tbody>
              </Table>
            </Box>
          </Box>
          <Flex justifyContent="right" mt={1}>
            {/* <Button
            mt={1}
              bg="#231F1F"
              color="#FFFFFF"
              _hover={{ bg: "#404040" }}
              borderRadius="full"
              size="xs"
              onClick={goToHomePage}
            >
             Home Page
            </Button> */}
            <ViewDetailsPagination
              currentPage={currentPage}
              totalPages={totalPages}
              handlePrevPage={handlePrevPage}
              handleNextPage={handleNextPage}
              fileCount={fileCount}
              setFileCount={setFileCount}
            />
          </Flex>
        </Box>

        {/* Add/Edit Profile Modal */}
        <AddSenderModal
          isOpen={isModalOpen}
          onClose={onCloseModal}
          profileData={selectedProfile} // Pass the profile data for editing
          onSave={handleSave} // Refresh profiles after saving
        />

        {/* Delete Confirmation Dialog */}
        <AlertDialog
          isOpen={isDeleteDialogOpen}
          leastDestructiveRef={null}
          onClose={() => setIsDeleteDialogOpen(false)}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Delete Profile
              </AlertDialogHeader>

              <AlertDialogBody>
                Are you sure you want to delete the profile? This action cannot
                be undone.
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button
                  variant="outline"
                  onClick={() => setIsDeleteDialogOpen(false)}
                >
                  No
                </Button>
                <Button colorScheme="red" onClick={handleDeleteProfile} ml={3}>
                  Yes
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </Box>
    </Box>
  );
}

export default SenderInformation;
