import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Box,
  Flex,
  Button,
  Container,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Link,
  Stack,
  Text,
  IconButton,
  InputGroup,
  InputRightElement,
  Checkbox,
  useToast,
  Image,
  Select,
  VStack,
} from "@chakra-ui/react";
import { HiEye, HiEyeOff } from "react-icons/hi";
import { useHistory, useLocation, Link as RouterLink } from "react-router-dom";
// import logo from "../assets/images/logo_auth.png"; // Adjust path if needed
import logo from "../assets/images/logo-home-dark.png";
import "./../styles/styles.css"; // Update or remove if needed

const content = [
  {
    heading: "Smart Focus AI",
    text: "Revolutionizing Personalized Outreach with Cutting-Edge AI Solutions.",
  },
  {
    heading: "Smart Focus AI",
    text: "Generate Personalized Emails for Your Leads, Instantly.",
  },
  {
    heading: "Smart Focus AI",
    text: "Create Tailored LinkedIn Messages for Enhanced Engagement.",
  },
  {
    heading: "Smart Focus AI",
    text: "Design Personalized Calling Scripts for Effective Conversations.",
  },
];

const SignUpPage = () => {
  const toast = useToast();
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Parse query params and fall back to default values
  const defaultPlanType = queryParams.get("plan_type") || "free";
  const defaultSubscriptionDuration =
    queryParams.get("subscription_duration") || "monthly";

  // Safely parse seat_count to a number if it exists; otherwise use null
  const seatCountParam = queryParams.get("seat_count");
  const defaultSeatCount = seatCountParam ? parseInt(seatCountParam, 10) : null;

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [planType, setPlanType] = useState(defaultPlanType);
  const [subscriptionDuration, setSubscriptionDuration] = useState(
    defaultSubscriptionDuration
  );
  const [seatCount, setSeatCount] = useState(defaultSeatCount);

  // Define available options
  const planTypes = ["free", "teams", "individual"];
  const subscriptionDurations = ["monthly", "annual"];

  const onSubmit = async (formData) => {
    if (formData.password !== formData.confirmPassword) {
      toast({
        title: "Password Mismatch",
        description: "The passwords do not match. Please try again.",
        status: "info",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    // Check if privacy policy and terms are accepted
    if (!formData.privacyPolicy || !formData.termsAccepted) {
      toast({
        title: "Terms & Privacy Policy",
        description:
          "You must accept the Privacy Policy and Terms of Service to continue.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const payload = {
      first_name: formData.firstName,
      last_name: formData.lastName,
      email: formData.email,
      password: formData.password,
      company_name: formData.companyName,
      privacy_policy_accepted: formData.privacyPolicy,
      terms_accepted: formData.termsAccepted,
      subscription: {
        plan_type: planType,
        subscription_duration: subscriptionDuration,
        // Only include seat_count if the plan is teams; otherwise null
        seat_count: planType === "teams" ? seatCount : null,
      },
    };
    console.log("payload", payload);
    setIsSubmitting(true);
    try {
      const response = await fetch("/auth/signup-public", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });

      const responseData = await response.json();

      if (response.ok && responseData.success) {
        toast({
          title: "Verification code sent",
          description: responseData.message,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        history.push("/verify-signup-2fa", { email: responseData.data.email });
        reset();
      } else {
        toast({
          title: "Failed to Create Account",
          description: responseData.message || "An error occurred.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Network Error",
        description: "Unable to connect to the server. Please try again later.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const [currentIndex, setCurrentIndex] = useState(0);
  const [animationTrigger, setAnimationTrigger] = useState(false);

  // Rotate content every 12 seconds
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => {
        setAnimationTrigger((prev) => !prev);
        return (prevIndex + 1) % content.length;
      });
    }, 12000);
    return () => clearInterval(timer);
  }, []);

  // Typewriter effect trigger after 10 seconds
  useEffect(() => {
    const textElement = document.querySelector(".typewriter");
    if (textElement) {
      const timer = setTimeout(() => {
        textElement.classList.add("wrapText");
      }, 10000);
      return () => clearTimeout(timer);
    }
  }, [currentIndex, animationTrigger]);

  return (
    <Flex h="100vh" overflowY="hidden" direction={{ base: "column", md: "row" }}>
      {/* Left Side with rotating content */}
      {/* <Box w="60%" bg="#FAC900" pt={5} pl={5} pb={40}>
      <Link as={RouterLink} to="/home">
        <Image src={logo} alt="Logo" width="100px" height="auto" />
        </Link>
        <Container w="100%" ml={0} pt={36}>
          <Heading
            // color="#231F1F"
            color="#0F123F"
            textAlign="left"
            fontFamily="'ui', sans-serif"
          >
            {content[currentIndex].heading}
          </Heading>
          <Text
            // color="#231F1F"
            color="#0F123F"
            className={`typewriter ${animationTrigger ? "alternate" : ""}`}
            key={currentIndex}
            textAlign="left"
            fontFamily="'ui', sans-serif"
            fontWeight="bold"
            fontSize="lg"
          >
            {content[currentIndex].text}
          </Text>
        </Container>
      </Box> */}
      <Box
        w="60%"
        minH="100vh" // Ensures the Box has enough height to center vertically
        bg="#FAC900"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <VStack>
          <Link as={RouterLink} to="/home">
            <Image src={logo} alt="Logo" width="300px" height="auto" />
          </Link>
          <Text
            color="#0F123F"
            className={`typewriter ${animationTrigger ? "alternate" : ""}`}
            key={currentIndex}
            textAlign="left"
            fontFamily="'ui', sans-serif"
            fontWeight="bold"
            fontSize="lg"
          >
            {content[currentIndex].text}
          </Text>
        </VStack>
      </Box>

      {/* Right Side - Signup Form */}
      <Box
        w={{ base: "100%", md: "40%" }}
        bg="white"
        p={{ base: 6, md: 5, lg: 6 }}
        alignContent="center"
        h="100vh"
        overflowY="auto"
      >
        <Container>
          <Stack spacing={2}>
            <Heading
              as="h1"
              size={{ base: "md", md: "md", lg: "lg" }}
              textAlign="left"
              mb={4}
              fontFamily="'Manrope', sans-serif"
              fontWeight="medium"
              color="#3A4135"
            >
              Create an Account
            </Heading>

            <form onSubmit={handleSubmit(onSubmit)}>
              {/* First Name and Last Name */}
              <HStack spacing={4} mb={4}>
                <FormControl isInvalid={errors.firstName} w="50%" isRequired>
                  <FormLabel fontSize="sm">First Name</FormLabel>
                  <Input
                    size="sm"
                    type="text"
                    rounded="lg"
                    placeholder="First Name"
                    {...register("firstName", {
                      required: "First name is required",
                    })}
                  />
                </FormControl>

                <FormControl isInvalid={errors.lastName} w="50%" isRequired>
                  <FormLabel fontSize="sm">Last Name</FormLabel>
                  <Input
                    size="sm"
                    type="text"
                    rounded="lg"
                    placeholder="Last Name"
                    {...register("lastName", {
                      required: "Last name is required",
                    })}
                  />
                </FormControl>
              </HStack>

              {/* Company Name */}
              <FormControl isInvalid={errors.companyName} mb={4} isRequired>
                <FormLabel fontSize="sm">Company Name</FormLabel>
                <Input
                  size="sm"
                  type="text"
                  rounded="lg"
                  placeholder="Company Name"
                  {...register("companyName", {
                    required: "Company name is required",
                  })}
                />
              </FormControl>

              {/* Email */}
              <FormControl isInvalid={errors.email} mb={4} isRequired>
                <FormLabel fontSize="sm">
                  Email (Valid Business Email Address)
                </FormLabel>
                <Input
                  size="sm"
                  type="email"
                  rounded="lg"
                  placeholder="Enter your email"
                  {...register("email", { required: "Email is required" })}
                />
              </FormControl>

              {/* Password */}
              <FormControl isInvalid={errors.password} mb={4} isRequired>
                <FormLabel fontSize="sm">Password</FormLabel>
                <InputGroup size="sm">
                  <Input
                    type={showPassword ? "text" : "password"}
                    rounded="lg"
                    placeholder="Password"
                    {...register("password", {
                      required: "Password is required",
                    })}
                  />
                  <InputRightElement>
                    <IconButton
                      size="xs"
                      onClick={() => setShowPassword(!showPassword)}
                      icon={showPassword ? <HiEyeOff /> : <HiEye />}
                      aria-label="Toggle password visibility"
                    />
                  </InputRightElement>
                </InputGroup>
              </FormControl>

              {/* Confirm Password */}
              <FormControl isInvalid={errors.confirmPassword} mb={4} isRequired>
                <FormLabel fontSize="sm">Confirm Password</FormLabel>
                <InputGroup size="sm">
                  <Input
                    type={showConfirmPassword ? "text" : "password"}
                    rounded="lg"
                    placeholder="Confirm Password"
                    {...register("confirmPassword", {
                      required: "Please confirm your password",
                    })}
                  />
                  <InputRightElement>
                    <IconButton
                      size="xs"
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      icon={showConfirmPassword ? <HiEyeOff /> : <HiEye />}
                      aria-label="Toggle confirm password visibility"
                    />
                  </InputRightElement>
                </InputGroup>
              </FormControl>

              {/* Plan Type Dropdown */}
              <FormControl mb={4} isRequired>
                <FormLabel fontSize="sm">Plan Type</FormLabel>
                <Select
                  size="sm"
                  rounded="lg"
                  value={planType}
                  onChange={(e) => setPlanType(e.target.value)}
                >
                  <option value="free">Free</option>
                  <option value="teams" disabled>
                    Teams (Coming Soon)
                  </option>
                  <option value="individual" disabled>
                    Individual (Coming Soon)
                  </option>
                </Select>
              </FormControl>

              {/* Seat Count Input (only for Teams plan) */}
              {planType === "teams" && (
                <FormControl mb={4} isRequired>
                  <FormLabel fontSize="sm">Seat Count</FormLabel>
                  <Input
                    size="sm"
                    type="number"
                    rounded="lg"
                    placeholder="Enter seat count"
                    value={seatCount || ""}
                    onChange={(e) => {
                      // Optionally ensure numeric input only
                      const val = e.target.value;
                      // Either allow empty (for backspace) or numeric
                      if (val === "" || /^[0-9\b]+$/.test(val)) {
                        setSeatCount(val);
                      }
                    }}
                    onKeyDown={(e) => {
                      // Prevent typing 'e', '+', '-', etc. in some browsers
                      if (
                        e.key === "e" ||
                        e.key === "E" ||
                        e.key === "+" ||
                        e.key === "-"
                      ) {
                        e.preventDefault();
                      }
                    }}
                  />
                </FormControl>
              )}

              {/* Subscription Duration Dropdown */}
              <FormControl mb={4} isRequired>
                <FormLabel fontSize="sm">Subscription Duration</FormLabel>
                <Select
                  size="sm"
                  rounded="lg"
                  value={subscriptionDuration}
                  onChange={(e) => setSubscriptionDuration(e.target.value)}
                >
                  {subscriptionDurations.map((duration) => (
                    <option key={duration} value={duration}>
                      {duration.charAt(0).toUpperCase() + duration.slice(1)}
                    </option>
                  ))}
                </Select>
              </FormControl>

              {/* Privacy Policy */}
              <FormControl mb={2} isRequired>
                <Checkbox size="sm" {...register("privacyPolicy")}>
                  I agree to the{" "}
                  <Link color="blue.500" href="/privacy-policy" isExternal>
                    Privacy Policy
                  </Link>
                </Checkbox>
              </FormControl>

              {/* Terms of Service */}
              <FormControl mb={2} isRequired>
                <Checkbox size="sm" {...register("termsAccepted")}>
                  I agree to the{" "}
                  <Link color="blue.500" href="/terms-of-service" isExternal>
                    Terms of Service
                  </Link>
                </Checkbox>
              </FormControl>
              {/* Submit Button */}
              <Button
                mt={2}
                size="sm"
                colorScheme="blue"
                type="submit"
                w="100%"
                bg="#231F1F"
                color="#FFFFFF"
                fontFamily="'Roboto', sans-serif"
                fontWeight="medium"
                _hover={{ bg: "#404040" }}
                isLoading={isSubmitting}
              >
                Sign Up
              </Button>

              {/* Login Link */}
              <Text
                mt={4}
                mb={0}
                fontSize="small"
                fontFamily="'Manrope', sans-serif"
                fontWeight="medium"
                color="#3A4135"
                textAlign="center"
              >
                Already have an account?{" "}
                <Link
                  href="/login"
                  fontFamily="'Manrope', sans-serif"
                  fontWeight="medium"
                  color="#0F123F"
                >
                  Login
                </Link>
              </Text>
            </form>
          </Stack>
        </Container>
      </Box>
    </Flex>
  );
};

export default SignUpPage;
