import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Stack,
  Heading,
  Text,
  Button,
  PinInput,
  PinInputField,
  HStack,
  Link,
  useToast,
  Image,
  Divider,
  Container,
} from "@chakra-ui/react";
import { useHistory, useLocation } from "react-router-dom";
// import logo from "../assets/images/logo_auth.png"; // Update path if needed
import logo from "../assets/images/logo-home-dark.png";
import "./../styles/styles.css"; // Adjust or remove if needed

const SignupVerificationPage = () => {
  const [verificationCode, setVerificationCode] = useState("");
  const [isVerifying, setIsVerifying] = useState(false);
  const [timer, setTimer] = useState(120); // 2-minute countdown in seconds
  const toast = useToast();
  const history = useHistory();
  const location = useLocation();

  // Retrieve the email from signup
  const email = location.state?.email || "";

  // If no email, redirect to signup
  useEffect(() => {
    if (!email) {
      history.push("/signup");
    }

    // Start the countdown timer
    const interval = setInterval(() => {
      setTimer((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);

    return () => clearInterval(interval);
  }, [email, history]);

  // Format the timer for mm:ss
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  // Handle verifying the signup 2FA
  const verifySignup2FA = async () => {
    setIsVerifying(true);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email, code: verificationCode }),
    };

    try {
      const response = await fetch("/auth/verify-signup-2fa", requestOptions);
      const data = await response.json();

      if (response.ok && data.success) {
        toast({
          title: "Signup Complete",
          description: data.message || "Account verified successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });

        // Check if session_url is present
        if (data.data && data.data.session_url) {
          // Open the payment link in a new tab
          window.open(data.data.session_url, "_blank");
        } else {
          // Redirect to login page
          history.push("/login");
        }
      } else {
        toast({
          title: "Verification Failed",
          description: data.message || "Your code is invalid or expired.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Network Error",
        description: "Unable to connect to the server.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    setIsVerifying(false);
  };

  return (
    <Flex minH="100vh" align="center" justify="center" bg="#F7F7F7">
      <Box
        w="full"
        maxW="md"
        bg="white"
        p={{ base: 6, md: 8 }}
        boxShadow="lg"
        borderRadius="lg"
      >
        {/* Optional logo */}
        <Flex justify="center" mb={4}>
          <Image src={logo} alt="Logo" width="200px" h="auto"  />
        </Flex>

        <Stack spacing={4} textAlign="center">
          <Heading
            size="md"
            fontFamily="'Manrope', sans-serif"
            color="#3A4135"
            fontWeight="medium"
          >
            Verify Your Signup
          </Heading>
          <Text color="gray.600" fontSize="sm">
            We've sent a 2FA code to:
            <br />
            <Text as="span" fontWeight="bold">
              {email}
            </Text>
            <br />
            Please enter the code below to complete your account creation.
          </Text>

          {/* Pin Input for 6-digit code */}
          <HStack justify="center">
            <PinInput
              otp
              size="lg"
              onChange={(value) => setVerificationCode(value)}
              placeholder=""
            >
              {Array(6)
                .fill("")
                .map((_, i) => (
                  <PinInputField key={i} />
                ))}
            </PinInput>
          </HStack>

          {/* Timer */}
          <Text fontSize="sm" color={timer > 0 ? "black" : "red"}>
            Time remaining: {formatTime(timer)}
          </Text>

          {timer === 0 && (
            <Text fontSize="sm" color="red">
              Your code has expired. Please{" "}
              <Link href="/signup" color="blue.600" fontWeight="medium">
                sign up
              </Link>{" "}
              again to request a new code.
            </Text>
          )}

          <Button
            colorScheme="blue"
            onClick={verifySignup2FA}
            isLoading={isVerifying}
            isDisabled={timer === 0 || verificationCode.length < 6}
          >
            Submit
          </Button>

          {/* <Text fontSize="sm" color="gray.600">
            Didn't receive your code?{" "}
            <Link
              color="blue.600"
              fontWeight="medium"
              onClick={() => {
                // Implement "resend code" if needed
                toast({
                  title: "Resend Code",
                  description:
                    "Please start the signup process again to request a new code.",
                  status: "info",
                  duration: 4000,
                  isClosable: true,
                });
              }}
            >
              Resend a new code
            </Link>
          </Text> */}
        </Stack>

        {/* Footer (Optional) */}
        <Box pt={10}>
          <HStack spacing={2} align="center" justify="center">
            <Link
              fontSize="sm"
              href="/terms-of-service"
              fontFamily="'Manrope', sans-serif"
              fontWeight="medium"
              color="blue.600"
            >
              Terms of Use
            </Link>
            <Divider
              orientation="vertical"
              height="12px"
              borderColor="blackAlpha.400"
              borderWidth="0.5px"
            />
            <Link
              fontSize="sm"
              href="/privacy-policy"
              fontFamily="'Manrope', sans-serif"
              fontWeight="medium"
              color="blue.600"
            >
              Privacy Policy
            </Link>
          </HStack>
        </Box>
      </Box>
    </Flex>
  );
};

export default SignupVerificationPage;
