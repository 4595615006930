import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Heading,
  useToast,
  useDisclosure,
  Button,
  Flex,
} from "@chakra-ui/react";
import io from "socket.io-client";
import axiosInstance from "../../../helpers/axiosInstance";
import UploadModal from "./UploadModal";
import TableRow from "./TableRow";
import DeleteDialog from "./DeleteDialog";
import SettingsModal from "./SettingsModal";

const socket = io(process.env.REACT_APP_SOCKET_SERVER, { transports: ["websocket"] });

const HistoryTable = ({
  selectedProgram,
  // handleViewDetails,
  data,
  setData,
  fetchSelectedProgram,
  selectedRow,
  setSelectedRow,
  contentType,
  setContentType,
}) => {
  const {
    isOpen: isUploadOpen,
    onOpen: onUploadOpen,
    onClose: onUploadClose,
  } = useDisclosure();
  const {
    isOpen: isSettingsOpen,
    onOpen: onSettingsOpen,
    onClose: onSettingsClose,
  } = useDisclosure();
  const joinedRoomRef = useRef(null);
  const toast = useToast();
  const [isDeleting, setIsDeleting] = useState({});
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const onCloseDeleteDialog = () => setIsDeleteDialogOpen(false);
  const [rowToDelete, setRowToDelete] = useState(null);
  // const [selectedRow, setSelectedRow] = useState(null);
  const cancelRef = React.useRef();
  const [unsavedManualData, setUnsavedManualData] = useState({});
  const [isCreating, setIsCreating] = useState(false);
  const [progressUpdates, setProgressUpdates] = useState({});

  useEffect(() => {
    if (selectedProgram) {
      const programId = selectedProgram._id;
      // Only join if we haven't joined this room yet.
      if (joinedRoomRef.current !== programId) {
        console.log("Joining room for program:", programId);
        socket.emit("join", { room: programId });
        joinedRoomRef.current = programId;
      }
    }

    const handleProgressUpdate = (data) => {
      console.log("Received progress_update event:", data);
      const computedProgress =
        typeof data.lead_index !== "undefined" &&
        typeof data.total_leads !== "undefined"
          ? `${data.lead_index} / ${data.total_leads}`
          : data.progress || data.status;

      setProgressUpdates((prev) => ({
        ...prev,
        [data.file_unique_id]: {
          program_id: data.program_id,
          file_unique_id: data.file_unique_id,
          execution_id: data.execution_id,
          progress: data.progress,
          type: data.type,
          status: data.status,
          message: data.message,
          lead_index: data.lead_index, // optional
          total_leads: data.total_leads, // optional
          computedProgress,
        },
      }));
    };

    socket.on("progress_update", handleProgressUpdate);

    return () => {
      socket.off("progress_update", handleProgressUpdate);
    };
  }, [selectedProgram]);

  useEffect(() => {
    // Load unsaved manual data from local storage on component mount
    const storedData = localStorage.getItem("unsavedManualData");
    if (storedData) {
      setUnsavedManualData(JSON.parse(storedData));
    }
  }, []);

  useEffect(() => {
    if (selectedProgram) {
      const fileList = selectedProgram.file_list || [];
      setData(fileList);
    }
  }, [selectedProgram]);

  useEffect(() => {
    // Save unsaved manual data to local storage whenever it changes
    localStorage.setItem(
      "unsavedManualData",
      JSON.stringify(unsavedManualData)
    );
  }, [unsavedManualData]);

  const handleOpenUpload = (row) => {
    setSelectedRow(row);
    onUploadOpen();
  };

  const handleOpenSettings = (row = null) => {
    setSelectedRow(row);
    onSettingsOpen();
  };

  const handleSaveSettings = (settingsValue) => {
    if (selectedRow) {
      const updatedData = data.map((item) =>
        item.file_unique_id === selectedRow.file_unique_id
          ? { ...item, setting: settingsValue }
          : item
      );
      setData(updatedData);
    }
  };

  const handleDelete = async () => {
    if (rowToDelete) {
      setIsDeleting((prev) => ({ ...prev, [rowToDelete.file_unique_id]: true }));
      try {
        await axiosInstance.delete(
          `/program/delete-file/${selectedProgram._id}/${rowToDelete.file_unique_id}`
        );
        setData((prevData) =>
          prevData.filter(
            (item) => item.file_unique_id !== rowToDelete.file_unique_id
          )
        );
        fetchSelectedProgram(selectedProgram._id);
        toast({
          title: "File deleted",
          description: `File ${rowToDelete.filename} has been deleted successfully.`,
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "top-right",
        });
      } catch (error) {
        console.error("Error deleting file:", error);
        toast({
          title: "Error",
          description:
            error.response?.data?.message ||
            "An error occurred while deleting the file.",
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "top-right",
        });
      } finally {
        setIsDeleting((prev) => ({ ...prev, [rowToDelete.file_unique_id]: false }));
        onCloseDeleteDialog();
      }
    }
  };

  const confirmDelete = (row) => {
    setRowToDelete(row);
    setIsDeleteDialogOpen(true);
  };

  const formatDate = (dateString) => {
    const [datePart] = dateString.split(" ");
    return datePart;
  };

  const handleCreateTemplate = async () => {
    setIsCreating(true);
    try {
      const response = await axiosInstance.post(
        `/program/create-file-template/${selectedProgram._id}`
      );
      if (response.data.success) {
        toast({
          title: "Template Created",
          description: response.data.message,
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "top-right",
        });
        setIsCreating(false);
        fetchSelectedProgram(selectedProgram._id);
      }
    } catch (error) {
      setIsCreating(false);
      console.error("Error creating template:", error);
      let errorMessage = "An error occurred while creating the template.";
      if (error.response?.data?.message) {
        errorMessage = error.response.data.message;
      } else if (error.message) {
        errorMessage = error.message;
      }
      toast({
        title: "Error",
        description: errorMessage,
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  // Remove surrounding quotes if present
  let content_type = contentType.replace(/^"(.*)"$/, "$1");

  // Capitalize the first letter
  const capitalizedContentType =
    content_type.charAt(0).toUpperCase() + content_type.slice(1);

  return (
    <Box
      width={{ base: "100%", md: "100%" }}
      h="100%"
      bg="white"
      borderRadius="xl"
      p={3}
      pb={1}
      borderColor="blackAlpha.200"
      borderWidth="1px"
    >
      <Flex justify="space-between" align="center" mb={4}>
        <Heading fontWeight="bold" fontSize="lg" ml={1} color="black">
          Campaign Generated - {capitalizedContentType} Content
        </Heading>
        <Button
          onClick={handleCreateTemplate}
          bg="#231F1F"
          color="#FFFFFF"
          _hover={{ bg: "#404040" }}
          borderRadius="full"
          size="sm"
          isLoading={isCreating}
        >
          Create
        </Button>
      </Flex>
      <Box h="85%" overflowY="auto">
        <Table variant="simple" size="sm" overflowX="auto">
          <Thead>
            <Tr>
              <Th textAlign="left" fontWeight="bold" width="10%">
                Date
              </Th>
              <Th textAlign="left" fontWeight="bold" width="10%">
                Product Profile
              </Th>
              <Th textAlign="left" fontWeight="bold" width="10%">
                Sender Profile
              </Th>
              <Th textAlign="center" fontWeight="bold" width="10%">
                Config
              </Th>
              <Th textAlign="center" fontWeight="bold" width="10%">
                {contentType === "company" ? "Target Company" : "Prospects"}
              </Th>
              <Th textAlign="left" fontWeight="bold" width="10%">
                Filename
              </Th>
              <Th textAlign="center" fontWeight="bold" width="10%">
                Generate Content
              </Th>
              <Th textAlign="center" fontWeight="bold" width="10%">
                Status
              </Th>
              <Th textAlign="center" fontWeight="bold" width="10%">
                Stop
              </Th>
              <Th textAlign="center" fontWeight="bold" width="10%">
                View Content
              </Th>
              <Th textAlign="center" fontWeight="bold" width="10%">
                Delete
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {Array.isArray(selectedProgram?.file_list) &&
            selectedProgram.file_list.length > 0 ? (
              selectedProgram.file_list.map((row, index) => (
                <TableRow
                  key={index}
                  row={row}
                  progressUpdate={progressUpdates[row.file_unique_id]}
                  formatDate={formatDate}
                  onUploadOpen={() => handleOpenUpload(row)}
                  handleOpenSettings={handleOpenSettings}
                  confirmDelete={confirmDelete}
                  isDeleting={isDeleting[row.file_unique_id] || false}
                  selectedProgramId={selectedProgram._id} // Pass program ID here
                  fetchSelectedProgram={fetchSelectedProgram}
                  executionId={
                    row?.executions && row.executions.length > 0
                      ? row.executions[0].execution_id
                      : ""
                  }
                  selectedProgram={selectedProgram}
                />
              ))
            ) : (
              <Tr>
                <Td colSpan="11" textAlign="center" fontWeight="semibold">
                  No file uploaded
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Box>

      <DeleteDialog
        isOpen={isDeleteDialogOpen}
        onClose={onCloseDeleteDialog}
        onDelete={handleDelete}
        cancelRef={cancelRef}
        isDeleting={rowToDelete ? isDeleting[rowToDelete.file_unique_id] || false : false}

      />

      <UploadModal
        isOpen={isUploadOpen}
        onClose={onUploadClose}
        selectedProgram={selectedProgram}
        fetchSelectedProgram={fetchSelectedProgram}
        fileUniqueId={selectedRow?.file_unique_id}
        unsavedManualData={unsavedManualData}
        setUnsavedManualData={setUnsavedManualData}
        contentType={contentType}
        setContentType={setContentType}
      />

      <SettingsModal
        isOpen={isSettingsOpen}
        onClose={onSettingsClose}
        selectedRow={selectedRow}
        onSave={handleSaveSettings}
        selectedProgram={selectedProgram}
        fetchSelectedProgram={fetchSelectedProgram}
      />
    </Box>
  );
};

export default HistoryTable;
