import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Textarea,
  RadioGroup,
  Radio,
  Checkbox,
  Input,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
  Tooltip,
  Flex,
  VStack,
  HStack,
  Select,
} from "@chakra-ui/react";
// import CreatableSelect from "react-select/creatable";
import axiosInstance from ".././../../helpers/axiosInstance";
import { InfoIcon } from "@chakra-ui/icons";
import { MdFiberManualRecord } from "react-icons/md";
// import countryList from "react-select-country-list";

const SettingsModal = ({
  isOpen,
  onClose,
  selectedRow,
  onSave,
  selectedProgram,
  fetchSelectedProgram,
  viewMode = false,
}) => {
  // console.log("SettingsModal",selectedRow);
  // console.log("viewMode",viewMode);
  const [settingsValue, setSettingsValue] = useState("");
  const [tags, setTags] = useState([]);
  const [outreachConfig, setOutreachConfig] = useState("");
  const [customOutreach, setCustomOutreach] = useState("");
  const [mentionCompetitor, setMentionCompetitor] = useState(null);
  const [meetingLink, setMeetingLink] = useState(null);
  const [greetingType, setGreetingType] = useState(null);
  // const [keyUsp, setKeyUsp] = useState("");
  const [creativity, setCreativity] = useState("");
  const [generatedInfoLength, setGeneratedInfoLength] = useState(1);
  const [contentGeneration, setContentGeneration] = useState({
    "1st_email": false,
    linkedin_connect_note: false,
    "2nd_email": false,
    linkedin_message: false,
    cold_calling_script: false,
  });
  const [isOtherChecked, setIsOtherChecked] = useState(false);
  const [emailStructure, setEmailStructure] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  // const [searchingCountry, setSearchingCountry] = useState("");
  //const countryOptions = useMemo(() => countryList().getData(), []);

  const initializeSettings = (setting) => {
    const isViewMode = !!setting.used_execution_setting; // Check if it's view mode
    // console.log("setting",setting);
    const activeSetting = isViewMode ? setting.used_execution_setting : setting;

    // setKeyUsp(activeSetting.key_usp || "");
    const outreach = [
      "Book a meeting",
      "Attend a webinar",
      "Grab a coffee",
      "Meet at a trade show",
      "Join a Round Table",
    ];
    setOutreachConfig(
      outreach.includes(activeSetting.outreach_configuration)
        ? activeSetting.outreach_configuration
        : ""
    );
    setCustomOutreach(
      outreach.includes(activeSetting.outreach_configuration)
        ? ""
        : activeSetting.outreach_configuration
    );
    setIsOtherChecked(
      !outreach.includes(activeSetting.outreach_configuration) &&
        activeSetting.outreach_configuration
    );
    setMentionCompetitor(activeSetting.mention_competitor ? "true" : "false");
    setMeetingLink(activeSetting.is_meeting_link ? "true" : "false");
    setGreetingType(activeSetting.greetings || "");
    setTags(
      (activeSetting.search_term_list || []).map((tag) => ({
        value: tag,
        label: tag,
      }))
    );
    setCreativity(activeSetting.creativity || "");
    setGeneratedInfoLength(
      activeSetting.generated_info_length === "low"
        ? 0
        : activeSetting.generated_info_length === "medium"
        ? 1
        : 2
    );
    setContentGeneration({
      "1st_email": (activeSetting.selected_content || []).includes("1st_email"),
      linkedin_connect_note: (activeSetting.selected_content || []).includes(
        "linkedin_connect_note"
      ),
      "2nd_email": (activeSetting.selected_content || []).includes("2nd_email"),
      linkedin_message: (activeSetting.selected_content || []).includes(
        "linkedin_message"
      ),
      cold_calling_script: (activeSetting.selected_content || []).includes(
        "cold_calling_script"
      ),
    });
    setEmailStructure(activeSetting.email_structure || "");
  };

  useEffect(() => {
    if (isOpen) {
      if (selectedRow === null && selectedProgram) {
        initializeSettings(selectedProgram.default_setting || {});
      } else if (selectedRow) {
        // Check if used_execution_setting exists
        if (selectedRow && viewMode === true) {
          initializeSettings({
            used_execution_setting: selectedRow,
          });
        } else {
          initializeSettings(selectedRow.setting || {});
        }
      }
    }
  }, [isOpen, selectedProgram, selectedRow]);

  // const initializeSettings = (setting) => {
  //   setKeyUsp(setting.key_usp || "");
  //   const outreach = [
  //     "Book a meeting",
  //     "Attend a webinar",
  //     "Grab a coffee",
  //     "Meet at a trade show",
  //     "Join a Round Table",
  //   ];
  //   setOutreachConfig(
  //     outreach.includes(setting.outreach_configuration)
  //       ? setting.outreach_configuration
  //       : ""
  //   );
  //   setCustomOutreach(
  //     outreach.includes(setting.outreach_configuration)
  //       ? ""
  //       : setting.outreach_configuration
  //   );
  //   setIsOtherChecked(
  //     !outreach.includes(setting.outreach_configuration) &&
  //       setting.outreach_configuration
  //   );
  //   setMentionCompetitor(setting.mention_competitor ? "true" : "false");
  //   setGreetingType(setting.greetings || "");
  //   setTags(
  //     (setting.search_term_list || []).map((tag) => ({
  //       value: tag,
  //       label: tag,
  //     }))
  //   );
  //   setCreativity(setting.creativity || "");
  //   setGeneratedInfoLength(
  //     setting.generated_info_length === "low"
  //       ? 0
  //       : setting.generated_info_length === "medium"
  //       ? 1
  //       : 2
  //   );
  //   setContentGeneration({
  //     "1st_email": (setting.selected_content || []).includes("1st_email"),
  //     linkedin_connect_note: (setting.selected_content || []).includes(
  //       "linkedin_connect_note"
  //     ),
  //     "2nd_email": (setting.selected_content || []).includes("2nd_email"),
  //     linkedin_message: (setting.selected_content || []).includes(
  //       "linkedin_message"
  //     ),
  //     cold_calling_script: (setting.selected_content || []).includes(
  //       "cold_calling_script"
  //     ),
  //   });
  //   setEmailStructure(setting.email_structure || "");
  //   //setSearchingCountry(setting.searching_news_in_country || "");
  // };

  // useEffect(() => {
  //   if (isOpen) {
  //     if (selectedRow === null && selectedProgram) {
  //       initializeSettings(selectedRow.default_setting || {});
  //     } else if (selectedRow) {
  //       initializeSettings(selectedRow.setting || {});
  //     }
  //   }
  // }, [isOpen, selectedProgram, selectedRow]);

  const handleSaveSettings = async () => {
    const settings = {
      selected_content: Object.keys(contentGeneration).filter(
        (key) => contentGeneration[key]
      ),
      outreach_configuration: customOutreach || outreachConfig,
      mention_competitor: mentionCompetitor === "true",
      is_meeting_link: meetingLink === "true",
      greetings: greetingType,
      // key_usp: keyUsp,
      search_term_list: tags.map((tag) => tag.value),
      creativity,
      generated_info_length: ["low", "medium", "high"][generatedInfoLength],
      email_structure: emailStructure,
      //searching_news_in_country: searchingCountry,
    };
    setIsSaving(true);
    if (selectedRow === null) {
      await axiosInstance.post(
        `/program/default-setting/${selectedProgram._id}`,
        settings
      );
      setIsSaving(false);
      fetchSelectedProgram(selectedProgram._id);
    } else {
      await axiosInstance.post(
        `/program/update-file-setting/${selectedProgram._id}/${selectedRow.file_unique_id}`,
        { setting: settings }
      );
      setIsSaving(false);
      fetchSelectedProgram(selectedProgram._id);
    }

    onSave(settings);
    setIsSaving(false);
    onClose();
  };

  // const handleChangeTags = (newValue) => {
  //   setTags(newValue);
  // };

  const handleChangeContentGeneration = (field) => {
    setContentGeneration((prev) => {
      const newContentGeneration = { ...prev, [field]: !prev[field] };
      if (field === "2nd_email" && !prev["1st_email"]) {
        newContentGeneration["1st_email"] = true;
      }
      return newContentGeneration;
    });
  };

  const handleOutreachConfigChange = (value) => {
    setOutreachConfig(value);
    setCustomOutreach("");
    setIsOtherChecked(false);
  };

  const handleOtherChange = (e) => {
    const checked = e.target.checked;
    setIsOtherChecked(checked);
    if (checked) {
      setOutreachConfig("");
    }
    setCustomOutreach("");
  };

  const handleCustomOutreachChange = (e) => {
    setCustomOutreach(e.target.value);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent h="78vh">
        <ModalHeader
          mr={4}
          ml={2}
          mb={4}
          borderBottom="1px"
          borderColor="blackAlpha.200"
        >
          {/* {selectedRow ? "Edit Personalized Content" : "Personalized Content"} */}
          {viewMode ? "View Execution Settings" : "Edit Execution Settings"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody ml={2} mr={2} overflow="auto">
          <Heading fontSize="md" fontWeight="bold" mb={0}>
            Outreach Configuration
          </Heading>
          <Text fontSize="xs" color="gray.500" mb={2}>
            What is the goal of your outreach?
          </Text>
          <Stack spacing={4} direction="column">
            <Checkbox
              isChecked={outreachConfig === "Book a meeting"}
              onChange={() => handleOutreachConfigChange("Book a meeting")}
              isReadOnly={viewMode}
            >
              <Text fontSize="sm" mb={0}>
                Book a meeting?
              </Text>
            </Checkbox>
            <Checkbox
              isChecked={outreachConfig === "Attend a webinar"}
              onChange={() => handleOutreachConfigChange("Attend a webinar")}
              isReadOnly={viewMode}
            >
              <Text fontSize="sm" mb={0}>
                Attend a webinar?
              </Text>
            </Checkbox>
            <Checkbox
              isChecked={outreachConfig === "Grab a coffee"}
              onChange={() => handleOutreachConfigChange("Grab a coffee")}
              isReadOnly={viewMode}
            >
              <Text fontSize="sm" mb={0}>
                Grab a coffee?
              </Text>
            </Checkbox>
            <Checkbox
              isChecked={outreachConfig === "Meet at a trade show"}
              onChange={() =>
                handleOutreachConfigChange("Meet at a trade show")
              }
              isReadOnly={viewMode}
            >
              <Text fontSize="sm" mb={0}>
                Meet at a trade show?
              </Text>
            </Checkbox>
            <Checkbox
              isChecked={outreachConfig === "Join a Round Table"}
              onChange={() => handleOutreachConfigChange("Join a Round Table")}
              isReadOnly={viewMode}
            >
              <Text fontSize="sm" mb={0}>
                Join a Round Table?
              </Text>
            </Checkbox>
            <Checkbox
              isChecked={isOtherChecked}
              onChange={handleOtherChange}
              isReadOnly={viewMode}
            >
              <Text fontSize="sm" mb={0}>
                Other
              </Text>
            </Checkbox>
            {isOtherChecked && (
              <Textarea
                placeholder="Custom Outreach Configuration"
                value={customOutreach}
                onChange={handleCustomOutreachChange}
                mt={2}
                fontSize="sm"
                borderRadius="xl"
                h="5vh"
                isReadOnly={viewMode}
              />
            )}
          </Stack>

          <Heading fontSize="md" fontWeight="bold" mt={4} mb={0}>
            Mention of Competitor
          </Heading>
          <Text fontSize="xs" color="gray.500" mb={2}>
            Would you like the email to mention competitors of the company you
            are targeting? This will be framed in the context that your company
            works with similar companies.
          </Text>
          <RadioGroup onChange={setMentionCompetitor} value={mentionCompetitor}>
            <Stack spacing={3} direction="row">
              <Radio value="true" isReadOnly={viewMode}>
                <Text fontSize="sm" mb={0}>
                  Yes
                </Text>
              </Radio>
              <Radio value="false" isReadOnly={viewMode}>
                <Text fontSize="sm" mb={0}>
                  No
                </Text>
              </Radio>
            </Stack>
          </RadioGroup>

          <Heading fontSize="md" fontWeight="bold" mt={4} mb={0}>
            Greetings
          </Heading>
          <Text fontSize="xs" color="gray.500" mb={2}>
            What type of greetings would you like?
          </Text>
          <Text fontSize="xs" color="gray.500" mb={2}>
            Select one:
          </Text>
          <RadioGroup onChange={setGreetingType} value={greetingType}>
            <Stack spacing={3} direction="row" align="center">
              <Radio value="personalized" isReadOnly={viewMode}>
                <HStack>
                  <Text fontSize="sm" mb={0}>
                    Friendly
                  </Text>
                  <Tooltip
                    label="example: Hi xyz, I hope you are doing well."
                    aria-label="Friendly greeting info"
                    // bg="white"
                    // textColor="black"
                  >
                    <InfoIcon ml={0} />
                  </Tooltip>
                </HStack>
              </Radio>
              <Radio value="direct" isReadOnly={viewMode}>
                <HStack>
                  <Text fontSize="sm" mb={0}>
                    Direct
                  </Text>
                  <Tooltip
                    label="example: John."
                    aria-label="Direct greeting info"
                  >
                    <InfoIcon ml={0} />
                  </Tooltip>
                </HStack>
              </Radio>
            </Stack>
          </RadioGroup>

          <Heading fontSize="md" fontWeight="bold" mt={4} mb={0}>
            Meeting Link
          </Heading>
          <Text fontSize="xs" color="gray.500" mb={2}>
            Would you like to add the metting link in the genereted output.
          </Text>
          <RadioGroup onChange={setMeetingLink} value={meetingLink}>
            <Stack spacing={3} direction="row">
              <Radio value="true" isReadOnly={viewMode}>
                <Text fontSize="sm" mb={0}>
                  Yes
                </Text>
              </Radio>
              <Radio value="false" isReadOnly={viewMode}>
                <Text fontSize="sm" mb={0}>
                  No
                </Text>
              </Radio>
            </Stack>
          </RadioGroup>

          <Heading fontSize="md" fontWeight="bold" mt={4} mb={0}>
            Email Structure
          </Heading>
          <Text fontSize="xs" color="gray.500" mb={2}>
            Select the email structure type:
          </Text>
          <Select
            value={emailStructure}
            onChange={(e) => setEmailStructure(e.target.value)}
            placeholder="Select email structure"
            size="md"
            borderRadius="xl"
            pointerEvents={viewMode ? "none" : "auto"} // Disable interaction in view mode
            opacity={viewMode ? 1 : 1}
          >
            <option value="VITO (Very Important Top Executive)">
              VITO (Very Important Top Executive)
            </option>
            <option value="Basho (Highly Personalized & Targeted)">
              Basho (Highly Personalized & Targeted)
            </option>
            <option value="AI Structure">AI Structure</option>
            <option value="AIDA (Attention, Interest, Desire, Action)">
              AIDA (Attention, Interest, Desire, Action)
            </option>
            <option value="PASTOR (Pain, Amplify, Story, Testimony, Offer, Response)">
              PASTOR (Pain, Amplify, Story, Testimony, Offer, Response)
            </option>
            <option value="FAB (Features, Advantages, Benefits)">
              FAB (Features, Advantages, Benefits)
            </option>
            <option value="3-B Structure (Build, Break, Benefit)">
              3-B Structure (Build, Break, Benefit)
            </option>
            <option value="The Challenger Sale Email">
              The Challenger Sale Email
            </option>
            <option value="The 4Ps Formula (Picture, Promise, Proof, Push)">
              The 4Ps Formula (Picture, Promise, Proof, Push)
            </option>
          </Select>

          <Heading fontSize="md" fontWeight="bold" mt={4} mb={0}>
            Creativity
          </Heading>
          <Text fontSize="xs" color="gray.500" mb={2}>
            Creativity tolerance level.
          </Text>
          <Stack spacing={3} direction="row">
            <Checkbox
              isChecked={creativity === "low"}
              onChange={() => setCreativity("low")}
              isReadOnly={viewMode}
            >
              <Text fontSize="sm" mb={0}>
                Factual
              </Text>
            </Checkbox>
            <Checkbox
              isChecked={creativity === "medium"}
              onChange={() => setCreativity("medium")}
              isReadOnly={viewMode}
            >
              <Text fontSize="sm" mb={0}>
                Little Creative
              </Text>
            </Checkbox>
            <Checkbox
              isChecked={creativity === "high"}
              onChange={() => setCreativity("high")}
              isReadOnly={viewMode}
            >
              <Text fontSize="sm" mb={0}>
                Very Creative
              </Text>
            </Checkbox>
          </Stack>
          <Heading fontSize="md" fontWeight="bold" mt={4} mb={0}>
            Content Generation
          </Heading>
          <Text fontSize="xs" color="gray.500" mb={2}>
            Select areas where you want to generate results.
          </Text>
          <Stack spacing={3} direction="column">
            <Checkbox
              isChecked={contentGeneration["1st_email"]}
              onChange={() => handleChangeContentGeneration("1st_email")}
              isReadOnly={viewMode}
            >
              <Text fontSize="sm" mb={0}>
                Initial Email
              </Text>
            </Checkbox>

            <Checkbox
              isChecked={contentGeneration.linkedin_connect_note}
              onChange={() =>
                handleChangeContentGeneration("linkedin_connect_note")
              }
              isReadOnly={viewMode}
            >
              <Text fontSize="sm" mb={0}>
                LinkedIn Connect Note
              </Text>
            </Checkbox>
            <Checkbox
              isChecked={contentGeneration["2nd_email"]}
              onChange={() => handleChangeContentGeneration("2nd_email")}
              isReadOnly={viewMode}
            >
              <Text fontSize="sm" mb={0}>
                Follow Up Email
              </Text>
            </Checkbox>
            <Checkbox
              isChecked={contentGeneration.linkedin_message}
              onChange={() => handleChangeContentGeneration("linkedin_message")}
              isReadOnly={viewMode}
            >
              <Text fontSize="sm" mb={0}>
                LinkedIn Message
              </Text>
            </Checkbox>
            <Checkbox
              isChecked={contentGeneration.cold_calling_script}
              onChange={() =>
                handleChangeContentGeneration("cold_calling_script")
              }
              isReadOnly={viewMode}
            >
              <Text fontSize="sm" mb={0}>
                Cold Calling Script
              </Text>
            </Checkbox>
          </Stack>
          <Heading fontSize="md" fontWeight="bold" mt={4} mb={0}>
            Generated Info Length
          </Heading>
          <Text fontSize="xs" color="gray.500" mb={2}>
            Generated Info Length.
          </Text>
          <Slider
            defaultValue={1}
            min={0}
            max={2}
            step={1}
            value={generatedInfoLength}
            onChange={(val) => setGeneratedInfoLength(val)}
            w="65%"
            isReadOnly={viewMode}
          >
            <SliderMark value={0} mt="3" ml="-2.5" fontSize="sm">
              Concise
            </SliderMark>
            <SliderMark value={1} mt="3" ml="-2.5" fontSize="sm">
              Standard
            </SliderMark>
            <SliderMark value={2} mt="3" ml="-2.5" fontSize="sm">
              Comprehensive
            </SliderMark>
            <SliderTrack bg="blue.300">
              <SliderFilledTrack bg="blue.600" />
            </SliderTrack>
            <SliderThumb boxSize={6}>
              <Box color="blue.900" as={MdFiberManualRecord} />
            </SliderThumb>
          </Slider>
        </ModalBody>
        <ModalFooter>
          {!viewMode && (
            <Button
              bg="#231F1F"
              color="#FFFFFF"
              _hover={{ bg: "#404040" }}
              borderRadius="full"
              onClick={handleSaveSettings}
              size="sm"
              isLoading={isSaving}
            >
              Save
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SettingsModal;
