import axiosInstance from "../helpers/axiosInstance";

export const createOrganization = async (payload) => {
  try {
    const response = await axiosInstance.post("/org/create-organization", payload);
    return response.data;
  } catch (error) {
    throw error.response?.data || error;
  }
};

export const getCurrentOrganization = async () => {
  try {
    const response = await axiosInstance.get("/org/get_current_organization");
    return response.data;
  } catch (error) {
    throw error.response?.data || error;
  }
};

export const getOrganizationByEmail = async (email) => {
  try {
    const response = await axiosInstance.post("/org/get-organization-by-email", {
      email,
    });
    return response.data;
  } catch (error) {
    throw error.response?.data || error;
  }
};

export const updateOrganization = async (organization) => {
  try {
    const response = await axiosInstance.post("/website_settings/update-organization", {
      new_organization: organization,
    });
    return response.data;
  } catch (error) {
    throw error.response?.data || error;
  }
};

export const fetchSettings = async (organization) => {
  try {
    const response = await axiosInstance.post("/org/fetch-organization-settings", {
      organization_name: organization,
    });
    return response.data;
  } catch (error) {
    throw error.response?.data || error;
  }
};

export const updateSettings = async (payload) => {
  try {
    const response = await axiosInstance.put("/org/update-organization-settings", payload);
    return response.data;
  } catch (error) {
    throw error.response?.data || error;
  }
};