import React from "react";
import { Box, Flex, Heading, Text, Button } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";

const PaymentFailurePage = () => {
  const history = useHistory();

  return (
    <Flex minH="100vh" align="center" justify="center" bg="#F7F7F7">
      <Box
        w="full"
        maxW="md"
        bg="white"
        p={{ base: 6, md: 8 }}
        boxShadow="lg"
        borderRadius="lg"
        textAlign="center"
      >
        <Heading size="lg" color="red.500">
          Payment Unsuccessful
        </Heading>
        <Text mt={4} color="gray.600">
          Unfortunately, your payment could not be processed. Please try again.
        </Text>
        <Button
          mt={6}
          colorScheme="blue"
          onClick={() => history.push("/signup")}
        >
          SignUp
        </Button>
      </Box>
    </Flex>
  );
};

export default PaymentFailurePage;