import React, { useState } from "react";
import {
  Box,
  VStack,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Divider,
  Button,
  useColorModeValue,
  useToast,
  Text,
  Heading,
  HStack,
  Icon,
  Card,
  CardBody,
} from "@chakra-ui/react";
import { updatePaymentMethod } from "../../api/stripeApi";
import { CreditCard } from "lucide-react"; // Importing an icon from lucide-react

const Billing = ({ sectionBg }) => {
  const toast = useToast();
  const [isUpdating, setIsUpdating] = useState(false);

  const handleUpdatePaymentMethod = async () => {
    setIsUpdating(true);
    try {
      const response = await updatePaymentMethod();
      // console.log("handleUpdatePaymentMethod",response);
      if (response.data.session_url) {
        window.open(response.data.session_url, "_blank");
      } else {
        toast({
          title: "Error",
          description: "No redirect URL found. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description: error.message || "Failed to update payment method.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <Box h="100vh" w="100%" overflowY="auto" bg={sectionBg}>
      <Breadcrumb spacing={2} pt={4} pl={4} pr={4} fontSize="lg" mb={0} ml="-8">
        <BreadcrumbItem>
          <BreadcrumbLink fontWeight="semibold" href="/settings/billing">
            Settings
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink href="/settings/billing">Billing</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Divider m={0} p={0} />

      <VStack p={8} align="start" spacing={8}>
        {/* Update Payment Method Section */}
        <Card w="100%" bg={useColorModeValue("white", "gray.700")} shadow="lg" borderRadius="lg">
          <CardBody>
            <HStack alignItems="center" spacing={4}>
              {/* Icon */}
              <Box p={3} bg={useColorModeValue("blue.50", "blue.900")} borderRadius="full">
                <Icon as={CreditCard} boxSize={8} color={useColorModeValue("blue.600", "blue.300")} />
              </Box>
              {/* Description */}
              <VStack align="start" spacing={1}>
                <Heading as="h3" size="md" color={useColorModeValue("gray.700", "white")}>
                  Update Payment Method
                </Heading>
                <Text fontSize="sm" color={useColorModeValue("gray.600", "gray.300")}>
                  Securely update your payment method for seamless transactions and uninterrupted service.
                </Text>
              </VStack>
            </HStack>

            {/* Button */}
            <VStack align="end" mt={4}>
              <Button
                colorScheme="blue"
                size="md"
                borderRadius="full"
                isLoading={isUpdating}
                onClick={handleUpdatePaymentMethod}
                shadow="md"
              >
                Update Payment Method
              </Button>
            </VStack>
          </CardBody>
        </Card>
      </VStack>
    </Box>
  );
};

export default Billing;
