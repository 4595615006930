import React, { useState, useEffect } from "react";
import { Box, Flex, useColorMode, useToast } from "@chakra-ui/react";
import usePersistedState from "../../components/usePersistedState";
import Program_CRUD from "../../components/Program/Program_CRUD";
import MiddlePanel from "../../components/Program/MiddlePannel/MiddlePanel";
import axiosInstance from "../../helpers/axiosInstance";
import { FiUpload } from "react-icons/fi";

const Program = () => {
  const { colorMode } = useColorMode();
  const toast = useToast();

  const [selectedProgram, setSelectedProgram] = usePersistedState(
    "selectedProgram",
    null
  );
  const [programName, setProgramName] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [fileCount, setFileCount] = useState(9);
  const [totalPages, setTotalPages] = useState(1);

  const [programs, setPrograms] = usePersistedState("programslist", []);
  const [showInputField, setShowInputField] = usePersistedState(
    "showInputFieldUploadProgram",
    false
  );
  const [currentProgram, setCurrentProgram] = usePersistedState(
    "currentProgramUploadProgram",
    ""
  );
  const [contentType, setContentType] = usePersistedState(
    "contentType",
    "personalised"
  );
  const [isAdding, setIsAdding] = useState(false);
  const fetchSelectedProgram = async (
    programId,
    page = currentPage,
    count = fileCount
  ) => {
    if (!programId) return;
    try {
      const response = await axiosInstance.post(
        `/program/fetch-program/${programId}`,
        {
          file_count: count,
          page_no: page,
        }
      );
      // console.log("response", response);
      if (response.status === 200) {
        setSelectedProgram(response.data.data);
        if (response.data.data.page_count) {
          setTotalPages(response.data.data.page_count);
        }
      }
    } catch (error) {
      console.error("Error fetching selected program:", error);
      let errorMessage = "An error occurred while fetching the program.";
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        errorMessage = error.response.data.message;
      } else if (error.message) {
        errorMessage = error.message;
      }
      toast({
        title: "Error",
        description: errorMessage,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const fetchAllPrograms = async () => {
    try {
      const content_type = contentType.replace(/^"(.*)"$/, "$1");
      const response = await axiosInstance.get(
        `/program/fetch-program/${content_type}`
      );
      if (response.status === 200) {
        // setSelectedProgram(null);
        const newPrograms = response.data.data.programs || [];
        setPrograms(newPrograms);
      }
    } catch (error) {
      console.error("Error fetching programs:", error);
      let errorMessage = "An error occurred while fetching the programs.";
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        errorMessage = error.response.data.message;
      } else if (error.message) {
        errorMessage = error.message;
      }
      toast({
        title: "Error",
        description: errorMessage,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const handleAddProgram = async () => {
    if (!currentProgram) return;
    setIsAdding(true);
    try {
      const content_type = contentType.replace(/^"(.*)"$/, "$1");
      const response = await axiosInstance.post("/program/create-program", {
        name: currentProgram,
        content_type: content_type,
      });
      if (response.status === 200) {
        toast({
          title: "Program Created",
          description: "Program created successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
        setShowInputField(false);
        setSelectedProgram(null);
        fetchAllPrograms();
        setCurrentProgram("");
        setIsAdding(false);
      }
    } catch (error) {
      setIsAdding(false);
      console.error("Error creating program:", error);
      let errorMessage = "An error occurred while creating the program.";
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        errorMessage = error.response.data.message;
      } else if (error.message) {
        errorMessage = error.message;
      }
      toast({
        title: "Error",
        description: errorMessage,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  // Fetch the list of all programs on mount
  useEffect(() => {
    fetchAllPrograms();
  }, []);

  // Once programs are fetched, if no program is currently selected,
  // set the default to the "latest" program (assuming it's at the end).
  // useEffect(() => {
  //   if (!selectedProgram && programs.length > 0) {
  //     // If your newest program is at the last position
  //     const latestProgram = programs[programs.length - 1];
  //     fetchSelectedProgram(latestProgram._id);
  //   }
  // }, [programs, selectedProgram]);

  return (
    <Flex flex="1" direction={{ base: "column", md: "row" }} bg="#F3F4F6">
      <Box
        width={{ base: "100%", md: "17%" }}
        height="100vh"
        display="flex"
        alignItems="top"
        justifyContent="top"
      >
        <Program_CRUD
          selectedProgram={selectedProgram}
          setSelectedProgram={setSelectedProgram}
          programName={programName}
          setProgramName={setProgramName}
          fetchSelectedProgram={fetchSelectedProgram}
          programs={programs}
          setPrograms={setPrograms}
          showInputField={showInputField}
          setShowInputField={setShowInputField}
          currentProgram={currentProgram}
          setCurrentProgram={setCurrentProgram}
          handleAddProgram={handleAddProgram}
          isAdding={isAdding}
          setIsAdding={setIsAdding}
          fetchAllPrograms={fetchAllPrograms}
          contentType={contentType}
          setContentType={setContentType}
        />
      </Box>

      <MiddlePanel
        selectedProgram={selectedProgram}
        setSelectedProgram={setSelectedProgram}
        fetchSelectedProgram={fetchSelectedProgram}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        fileCount={fileCount}
        setFileCount={setFileCount}
        programs={programs}
        setPrograms={setPrograms}
        showInputField={showInputField}
        setShowInputField={setShowInputField}
        currentProgram={currentProgram}
        setCurrentProgram={setCurrentProgram}
        handleAddProgram={handleAddProgram}
        totalPages={totalPages}
        setTotalPages={setTotalPages}
        contentType={contentType}
        setContentType={setContentType}
      />
    </Flex>
  );
};

export default Program;
