import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Button,
  Icon,
  Input,
  Link,
  Select,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  VStack,
  HStack,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  IconButton,
  Checkbox,
} from "@chakra-ui/react";
import { FiUpload, FiPlus, FiTrash } from "react-icons/fi";
import axiosInstance from "../../../helpers/axiosInstance";
import Upload from "./Upload";
import countryList from "react-select-country-list";

const UploadModal = ({
  isOpen,
  onClose,
  selectedProgram,
  fetchSelectedProgram,
  fileUniqueId,
  unsavedManualData,
  setUnsavedManualData,
  contentType,
}) => {
  const [file, setFile] = useState(null);
  const [manualData, setManualData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const toast = useToast();

  const countryOptions = useMemo(() => countryList().getData(), []);
  const [isUploadLoading, setIsUploadLoading] = useState(false);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  /**
   * 1) Columns: if "company", we use searching_news_in_country,
   *    else we also use searching_news_in_country (but treat it as "personalized").
   *    We'll rename it in the header so the user sees "target_country" in the UI.
   */
  const columns = useMemo(() => {
    if (contentType === "company") {
      return [
        "prospect_company_name",
        "prospect_company_website_url",
        "searching_news_in_country",
      ];
    } else {
      // personalized
      return [
        "prospect_name",
        "prospect_company_name",
        "prospect_title",
        "prospect_linkedin_about_section",
        "searching_news_in_country",
      ];
    }
  }, [contentType]);

  useEffect(() => {
    if (selectedProgram?.file_list?.length > 0) {
      const selectedFile = selectedProgram.file_list.find(
        (f) => f.file_unique_id === fileUniqueId
      );
      const fileData = selectedFile ? selectedFile.file_data : [];

      // Map the file data to the active columns
      const mappedData = fileData.map((data) => {
        const newRow = {};
        columns.forEach((col) => {
          newRow[col] = data[col] || "";
        });
        // Example: uppercase searching_news_in_country if it exists
        if ("searching_news_in_country" in newRow) {
          newRow.searching_news_in_country =
            newRow.searching_news_in_country.toUpperCase();
        }
        return newRow;
      });

      // Use unsaved data if available; otherwise the mapped data
      setManualData(unsavedManualData[fileUniqueId] || mappedData);
    }
  }, [selectedProgram, fileUniqueId, columns, unsavedManualData]);

  // File upload and drag-drop logic
  const handleFileUpload = (e) => setFile(e.target.files[0]);
  const handleDrop = (e) => {
    e.preventDefault();
    if (e.dataTransfer.files.length > 0) {
      setFile(e.dataTransfer.files[0]);
    }
  };
  const handleDragOver = (e) => e.preventDefault();

  const handleUploadFile = async () => {
    if (!file) {
      toast({
        title: "No file selected",
        description: "Please select a file to upload.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    if (!selectedProgram) {
      toast({
        title: "No program selected",
        description: "Please select a program before uploading.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    // Set loading state to true before starting the upload
    setIsUploadLoading(true);

    try {
      await Upload(file, selectedProgram, fileUniqueId, toast);
      setFile(null);
      onClose();
      fetchSelectedProgram(selectedProgram._id);
    } catch (error) {
      console.error("Error uploading file:", error);
      toast({
        title: "Error uploading file",
        description:
          error.message || "An error occurred while uploading the file.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      // Reset the loading state once the upload is done or if an error occurs
      setIsUploadLoading(false);
    }
  };

  // Download template logic
  const handleDownloadTemplate = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      const content_type = contentType.replace(/^"(.*)"$/, "$1");
      const response = await axiosInstance.get(
        `/program/download-template/${content_type}`,
        {
          responseType: "blob",
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;

      const contentDisposition = response.headers["content-disposition"];
      let filename = "template.csv";

      if (
        contentDisposition &&
        contentDisposition.indexOf("attachment") !== -1
      ) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(contentDisposition);
        if (matches && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
        }
      }

      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      let errorMessage = "An error occurred while uploading the file.";
      if (error.response?.data?.message) {
        errorMessage = error.response.data.message;
      } else if (error.message) {
        errorMessage = error.message;
      }
      console.error("Error uploading file:", error);
      toast({
        title: "Error",
        description: errorMessage,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  // Manual data handlers
  const handleInputChange = (rowIndex, field, value) => {
    const updatedData = [...manualData];
    updatedData[rowIndex][field] = value;
    setManualData(updatedData);

    // Save unsaved data
    const newUnsavedData = {
      ...unsavedManualData,
      [fileUniqueId]: updatedData,
    };
    setUnsavedManualData(newUnsavedData);
    localStorage.setItem("unsavedManualData", JSON.stringify(newUnsavedData));
  };

  const handleAddRow = () => {
    const newRow = {};
    columns.forEach((col) => {
      newRow[col] = "";
    });
    setManualData([...manualData, newRow]);
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]);
    } else {
      setSelectedRows(manualData.map((_, index) => index));
    }
    setSelectAll(!selectAll);
  };
  const handleSelectRow = (index) => {
    setSelectedRows((prev) => {
      const newSelectedRows = prev.includes(index)
        ? prev.filter((i) => i !== index)
        : [...prev, index];
      setSelectAll(newSelectedRows.length === manualData.length);
      return newSelectedRows;
    });
  };
  const handleRemoveSelectedRows = () => {
    setManualData((prev) =>
      prev.filter((_, index) => !selectedRows.includes(index))
    );
    setSelectedRows([]);
  };

  const handleSaveManualData = async () => {
    if (manualData.length === 0) {
      toast({
        title: "No data entered",
        description: "Please enter some data before saving.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    if (!selectedProgram) {
      toast({
        title: "No program selected",
        description: "Please select a program before saving.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    // Convert searching_news_in_country to lowercase if needed
    const dataToSend = manualData.map((row) => ({
      ...row,
      searching_news_in_country:
        row.searching_news_in_country?.toLowerCase?.() || "",
    }));
    setIsSaveLoading(true);

    try {
      const updatedData = await Upload(
        dataToSend,
        selectedProgram,
        fileUniqueId,
        toast,
        true
      );

      if (updatedData) {
        const newUnsavedData = { ...unsavedManualData };
        delete newUnsavedData[fileUniqueId];
        setUnsavedManualData(newUnsavedData);
        localStorage.setItem(
          "unsavedManualData",
          JSON.stringify(newUnsavedData)
        );

        setManualData([]);
        onClose();
        fetchSelectedProgram(selectedProgram._id);
        setIsSaveLoading(false);
      } else {
        setIsSaveLoading(false);
        console.log("Upload failed, data not cleared.");
      }
    } catch (error) {
      setIsSaveLoading(false);
      console.error("Failed to save data:", error);
      toast({
        title: "Save failed",
        description: "Unable to save data. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  /**
   *
   * Helper function: if the column is "searching_news_in_country",
   * show "target_country" in the table header
   */
  const getColumnHeader = (col) => {
    if (col === "searching_news_in_country") {
      return "target_country";
    }
    return col;
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
      overflowY="hidden"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent h="80vh" maxH="100vh" maxWidth="91%" overflow="hidden">
        <ModalHeader ml={2}>Upload or Enter Data</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Tabs>
            <TabList>
              <Tab>Web Form</Tab>
              <Tab>Upload File</Tab>
            </TabList>

            <TabPanels>
              {/* 1) Manual Data Entry Tab */}
              <TabPanel>
                <VStack
                  spacing={4}
                  align="stretch"
                  h="45vh"
                  overflowY="auto"
                  sx={{
                    "&::-webkit-scrollbar": { width: "8px" },
                    "&::-webkit-scrollbar-track": {
                      background: "#f1f1f1",
                      borderRadius: "10px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      background: "#888",
                      borderRadius: "10px",
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                      background: "#555",
                    },
                    scrollbarWidth: "thin",
                    scrollbarColor: "#888 #f1f1f1",
                  }}
                >
                  <HStack justifyContent="space-between">
                    <IconButton
                      onClick={handleRemoveSelectedRows}
                      icon={<FiTrash />}
                      colorScheme="red"
                      size="sm"
                      aria-label="Remove Selected Rows"
                      isDisabled={selectedRows.length === 0}
                    />
                    <IconButton
                      onClick={handleAddRow}
                      icon={<FiPlus />}
                      colorScheme="green"
                      size="sm"
                      aria-label="Add Row"
                    />
                  </HStack>

                  <Box>
                    <Table variant="simple" size="sm">
                      <Thead>
                        <Tr>
                          <Th display="flex" alignItems="center" pr={2}>
                            <Checkbox
                              isChecked={selectAll}
                              onChange={handleSelectAll}
                            />
                            <Text ml={2} mb={0}>
                              {selectedRows.length > 0
                                ? "Select All"
                                : "Select"}
                            </Text>
                          </Th>
                          {/* Re-label 'searching_news_in_country' => 'target_country' */}
                          {columns.map((col, index) => (
                            <Th key={index} pr={2}>
                              {getColumnHeader(col)}
                            </Th>
                          ))}
                        </Tr>
                      </Thead>
                      <Tbody>
                        {manualData.map((row, rowIndex) => (
                          <Tr key={rowIndex}>
                            <Td>
                              <Checkbox
                                isChecked={selectedRows.includes(rowIndex)}
                                onChange={() => handleSelectRow(rowIndex)}
                              />
                            </Td>

                            {columns.map((col) => (
                              <Td key={col}>
                                {/* 
                                    If col is searching_news_in_country
                                    show a dropdown for "country" 
                                */}
                                {col === "searching_news_in_country" ? (
                                  <Select
                                    value={row[col] || ""}
                                    onChange={(e) =>
                                      handleInputChange(
                                        rowIndex,
                                        col,
                                        e.target.value
                                      )
                                    }
                                    placeholder="Select country"
                                    size="xs"
                                    borderRadius="xl"
                                  >
                                    {countryOptions.map((country) => (
                                      <option
                                        key={country.value}
                                        value={country.value}
                                      >
                                        {country.label}
                                      </option>
                                    ))}
                                  </Select>
                                ) : (
                                  <Input
                                    value={row[col] || ""}
                                    onChange={(e) =>
                                      handleInputChange(
                                        rowIndex,
                                        col,
                                        e.target.value
                                      )
                                    }
                                    placeholder={`Enter ${col}`}
                                    size="xs"
                                    borderRadius="xl"
                                  />
                                )}
                              </Td>
                            ))}
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </Box>
                </VStack>

                <Box display="flex" justifyContent="flex-end" mt={4}>
                  <Button
                    bg="#231F1F"
                    color="#FFFFFF"
                    _hover={{ bg: "#404040" }}
                    borderRadius="full"
                    onClick={handleSaveManualData}
                    size="sm"
                    isLoading={isSaveLoading}
                  >
                    Save
                  </Button>
                </Box>
              </TabPanel>

              {/* 2) File Upload Tab */}
              <TabPanel>
                <Box
                  display="flex"
                  h="45vh"
                  width="100%"
                  textAlign="center"
                  alignContent="center"
                  justifyContent="center"
                >
                  <Box
                    border="1px dashed"
                    borderColor="gray.200"
                    borderRadius="md"
                    p={4}
                    textAlign="center"
                    alignContent="center"
                    width="50%"
                    position="relative"
                    cursor="pointer"
                    onClick={() =>
                      document.getElementById("file-upload")?.click()
                    }
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                  >
                    <Icon as={FiUpload} boxSize={20} mb={4} mx="auto" />
                    <Text fontSize="md" fontWeight="bold" mb={2}>
                      Drop the files here or{" "}
                      <Link color="#3C9AFF">choose a file</Link>
                    </Text>
                    <Text fontSize="sm" color="gray.500" mb={1}>
                      .xlsx
                    </Text>
                    <Link
                      fontSize="sm"
                      color="#3C9AFF"
                      href="#"
                      onClick={handleDownloadTemplate}
                    >
                      download template here
                    </Link>
                    <Input
                      id="file-upload"
                      type="file"
                      onChange={handleFileUpload}
                      opacity={0}
                      position="absolute"
                      top={0}
                      left={0}
                      right={0}
                      bottom={0}
                      cursor="pointer"
                      zIndex={-1}
                    />
                    {file && (
                      <Text fontSize="sm" mt={2}>
                        {file.name}
                      </Text>
                    )}
                  </Box>
                </Box>
                <Box display="flex" justifyContent="flex-end" mt={4}>
                  <Button
                    bg="#231F1F"
                    color="#FFFFFF"
                    _hover={{ bg: "#404040" }}
                    borderRadius="full"
                    onClick={handleUploadFile}
                    size="sm"
                    isLoading={isUploadLoading}
                  >
                    Upload File
                  </Button>
                </Box>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default UploadModal;
